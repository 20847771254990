import React, { useState, useEffect, useContext, useRef } from 'react';
import { Icon, Label, Form, Input, Message, List, Grid, Checkbox, Tab, Segment, Button, ButtonGroup, ButtonOr } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import useAuth from '../../../../hooks/useAuth';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from '../../../../LanguageAGG';
import { useToasts } from 'react-toast-notifications';
import { addDateToDate, changeFormatDate, changePageName, checkDateStartBeforeNow, checkiscompanypay, getlinkcycle, packageColor, removeEmojis, removeSpecialChars } from '../../../../myfunctions';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import AGGridActivity from '../../../../AGGridActivity';
import axios from '../../../../axios';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import CountDownTimer from '../../countDownTimer/CountDownTimer';
import BuySubscription from '../../SinglePage/Other/BuySubscription';
import MyActivities from '../Activities/MyActivities';
import AddMyActivities from '../Activities/AddMyActivities';
import { useHistory } from "react-router-dom";
import BuyProductCompany from '../../SinglePage/Other/BuyProductCompany';
import CloneTournament from './CloneTournament';
import CloneActivities from '../Activities/CloneActivities';
const defHeightList = 600;

function MyTournaments(props) {
  const [activeIndex, setactiveIndex] = useState(0)
  const { addToast } = useToasts();
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const authContext = useContext(AuthContext);
  const gridRef = useRef();
  const gridRefCykle = useRef();

  const [cloneTournamentOpenModal, setcloneTournamentOpenModal] = useState(null);
  const [cloneActivitiesOpenModal, setcloneActivitiesOpenModal] = useState(null);

  const [form, setForm] = useState({// eslint-disable-line no-unused-vars
    cykl: '',
    tournament: '',
  });
  const isDemo = useCheckUserIsDemo();
  let history = useHistory();

  const [formAddCykl, setFormAddCykl] = useState({// eslint-disable-line no-unused-vars
    cykl: '',
  });



  const [formRenameCykl, setformRenameCykl] = useState({// eslint-disable-line no-unused-vars
    cyklName: '',
    cyklId: 0,
  });


  const [formAddCyklSub, setFormAddCyklSub] = useState({// eslint-disable-line no-unused-vars
    cycle_id: 0,
    tournament_id: 0,
    subtournament_id: 0,
    subtournament_name: ""
  });


  // cykle
  const [gridApiCykle, setGridApiCykle] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApiCykle, setGridColumnApiCykle] = useState(null);// eslint-disable-line no-unused-vars
  const [rowDataCykle, setRowDataCykle] = useState([]);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


  const [countSubtournamentAnalisis, setcountSubtournamentAnalisis] = useState({ value: 0, newValue: 0 });
  const [TournamentCycle, setTournamentCycle] = useState([]);// eslint-disable-line no-unused-vars
  const [Tournament, setTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [SubTournament, setSubTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [checkboxarch, setcheckboxarch] = useState(true);

  const [cycleIsGrouped, setcycleIsGrouped] = useState({ cycle_id: 0, group_subtournaments: false });

  const gridRefCykleAG = useRef();
  const onGridReadyCykle = (params) => {
    setGridApiCykle(params.api)
    setGridColumnApiCykle(params.columnApi)
  };

  function savecountSubtournamentAnalisis(cycle_id) {
    ConnectWS("/generator/setCycleCountSubtournaments", authContext.token, { cycle_id: cycle_id, count_subtournaments: Number(countSubtournamentAnalisis.newValue) > 0 ? Number(countSubtournamentAnalisis.newValue) : 0 }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {
        setcountSubtournamentAnalisis({ value: 0, newValue: 0 })
        getCycles()
        setformRenameCykl({ ...formRenameCykl, cyklId: -1 })
        addToast(response.data.message, { appearance: 'success' });
        setFormAddCyklSub({// eslint-disable-line no-unused-vars
          cycle_id: -1,
          tournament_id: 0,
          subtournament_id: 0,
          subtournament_name: ""
        });
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }



    });
  }

  function getTournaments(checkboxarchTMP) {
    ConnectWS("/generator/getTournaments", authContext.token, { skip_archived: checkboxarchTMP }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          if (data.tournament_archived === 0) {
            if (!checkDateStartBeforeNow(addDateToDate(data.start_date, 365))) {
            } else {
              dictemat.push({ key: i, text: changeFormatDate(data.start_date) + " - " + data.name, value: data.tournament_id })
            }
          }
        })
        setRowDataTournament(response.data)
        setTournament(dictemat);
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

        //console.log(response);
      }



    });
  }

  const rowDelCykle = props => {
    const rowDelQ = () => {
      confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz skasować przypisana kategorię: ' + props.data["subtournamentName"],
        buttons: [
          {
            label: 'Tak',
            onClick: () => removeSubtournamentFromCycle(props.data["cycleid"], props.data["subtournament_id"], props.data["subtournamentName"])
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });

      function removeSubtournamentFromCycle(cycle_id, subtournament_id, subtournamentName) {
        ConnectWS("/generator/removeSubtournamentFromCycle", authContext.token, { cycle_id: cycle_id, subtournament_id: subtournament_id }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);
          } else if (response && response.status && Number(response.status) === 200) {
            //setRowDataTournament(response.data)
            addToast(response.data.message, { appearance: 'success' });
            showSubTournament1(subtournamentName, cycle_id)
            // getTournaments(checkboxarch)
            setFormAddCyklSub({ ...formAddCyklSub, cycle_id: -1, subtournament_id: 0, tournament_id: 0, subtournament_name: "" })
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //console.log(response);
          }



        });
      }

    };
    return <div style={{ cursor: "pointer" }} onClick={rowDelQ} ><Icon name='trash' style={{ fontSize: 16 }} color="orange" /></div>
  };

  const urlCycle = props => {

    if (props.data.urlCycleAll) {
      return <span>{getlinkcycle(props.data["cycleid"], props.data["cycleName"], props.data["urlCycleAll"])}</span>
    } else {
      return <span>{getlinkcycle(props.data["cycleid"], props.data["cycleName"], props.data["subtournamentName"])}</span>
    }

  }

  const rowEditCykle = props => {
    const rowEditQ = () => {

      ConnectWS("/generator/changeSubtournamentCycleAnalysis", authContext.token, { cycle_id: props.data["cycleid"], subtournament_id: props.data["subtournament_id"] }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          showSubTournament1(props.data["subtournamentName"], props.data["cycleid"])
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }


    return <div style={{ cursor: "pointer" }} onClick={rowEditQ} > {props.data.analysis === 1 ? <Icon name='eye' color="green" style={{ fontSize: 16 }} title="kategoria uwzgledniona w cyklu" /> : <Icon name='eye' style={{ fontSize: 16 }} color="red" title="kategoria nieuwzględniona w cyklu" />}</div>
  };

  //

  //turnieje
  const [gridApiTournament, setGridApiTournament] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApiTournament, setGridColumnApiTournament] = useState(null);// eslint-disable-line no-unused-vars
  const [rowDataTournament, setRowDataTournament] = useState(null);
  const gridRefAG = useRef();

  const onGridReadyTournament = (params) => {
    setGridApiTournament(params.api)
    setGridColumnApiTournament(params.columnApi)

  };



  const rowRO = props => {
    return props.data.register_online === "TAK" ? <Icon name='check' color="green" style={{ fontSize: 16 }} title="TAK" /> : <Icon name='x' style={{ fontSize: 16 }} color="grey" title="NIE" />
  };



  const rowDisableView = props => {
    const rowDisableViewQ = () => {

      ConnectWS("/generator/setTournamentDisableView ", authContext.token, { tournament_id: props.data.tournament_id, disable_view: props.data.disable_view === 1 ? 0 : 1 }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          getTournaments(props.data.skip_archived)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }


    return <div style={{ cursor: "pointer" }} onClick={rowDisableViewQ} > {props.data.disable_view === 1 ? <Icon name='eye' color="grey" style={{ fontSize: 16 }} title="Widoczność rozgrywek na dedykowanej stronie - Wyłączona" /> : <Icon name='eye' style={{ fontSize: 16 }} color="green" title="Widoczność rozgrywek na dedykowanej stronie - Włączona" />}</div>
  };

  const rowEditTArchived = props => {
    const rowEditTArchivedQ = () => {

      ConnectWS("/generator/archiveTournament", authContext.token, { tournament_id: props.data.tournament_id, tournament_archived: props.data.tournament_archived === 1 ? false : true }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          getTournaments(props.data.skip_archived)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }


    return <div style={{ cursor: "pointer" }} onClick={rowEditTArchivedQ} > {props.data.tournament_archived === 1 ? <Icon name='archive' color="grey" style={{ fontSize: 16 }} title="Przywrócić" /> : <Icon name='archive' style={{ fontSize: 16 }} color="green" title="Zarchiwizuj" />}</div>
  };



  const rowDelTournament = props => {
    const rowDelQ = () => {
      confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz skasować rozgrywki: ' + props.data["name"],
        buttons: [
          {
            label: 'Tak',
            onClick: () => delTurnament(props.data["tournament_id"])
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });

      function delTurnament(tournamentId) {
        ConnectWS("/generator/removeTournament", authContext.token, { tournament_id: tournamentId }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);
          } else if (response && response.status && Number(response.status) === 200) {
            //setRowDataTournament(response.data)
            addToast(response.data.message, { appearance: 'success' });
            getTournaments(checkboxarch)
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //console.log(response);
          }



        });
      }


    };
    return <div style={{ cursor: "pointer" }} onClick={rowDelQ} ><Icon name='trash' style={{ fontSize: 16 }} color="red" title="Usuń rozgrywki" /></div>
  };

  function rowDelTournamentCykl() {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować przypisany cykl?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => delTurnamentCykl()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function delTurnamentCykl() {
      if (formAddCyklSub.cycle_id !== 0) {
        ConnectWS("/generator/removeCycle", authContext.token, { cycle_id: JSON.parse(formAddCyklSub.cycle_id).cycle_id }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);
          } else if (response && response.status && Number(response.status) === 200) {
            //setRowDataTournament(response.data)
            addToast(response.data.message, { appearance: 'success' });
            getTournaments(checkboxarch)
            getCycles()
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //console.log(response);
          }



        });
      } else {
        addToast("Proszę wybrać cykl do skasowania", { appearance: 'warning' });
      }

    }


  };



  const rowEditTournament = props => {

    return <div style={{ cursor: "pointer" }} onClick={() => authContext.changeView({ myView: "MyTournament", tournamentId: props.data["tournament_id"] })} ><Icon style={{ fontSize: 16 }} name='edit' title="Przejdź do rozgrywek" /></div>
  };

  const rowCopy = props => {
    return <div style={{ cursor: "pointer" }} onClick={() => setcloneTournamentOpenModal(props.data)} ><Icon style={{ fontSize: 16 }} name='clone' title="Kopiuj turniej" /></div>
  };

  const rowCloneA = props => {
    return <div style={{ cursor: "pointer" }} onClick={() => setcloneActivitiesOpenModal(props.data)} ><Icon style={{ fontSize: 16 }} name='clone' title="Kopiuj Trening" /></div>
  };

  //

  useEffect(() => {
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyTournaments")
    }

    changePageName()
    getTournaments(checkboxarch)
    getActivities(checkboxarch)
    getCycles()
    getSharedTournaments()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [authContext.refreshView])// eslint-disable-line react-hooks/exhaustive-deps








  const submitAddTournamenttoCykl = e => {
    e.preventDefault();

    if (Number(JSON.parse(formAddCyklSub.cycle_id).cycle_id) > 0) {

    } else {
      addToast("Proszę wybrać cykl", { appearance: 'warning' });
      return;
    }
    if (formAddCyklSub.subtournament_id === 0) {
      addToast("Proszę wybrać kategorię", { appearance: 'warning' });
      return;
    }
    ConnectWS("/generator/addSubtournamentToCycle", authContext.token, { cycle_id: JSON.parse(formAddCyklSub.cycle_id).cycle_id, subtournament_id: formAddCyklSub.subtournament_id }, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        setFormAddCyklSub({
          ...formAddCyklSub, cycle_id: 0,
          tournament_id: 0,
          subtournament_id: 0,
          subtournament_name: ""
        })
        getTournaments(checkboxarch)

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      // setloading(false)
    });


  }

  function getCycles() {
    //setloading(true)
    ConnectWS("/generator/getCycles", authContext.token, {}, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        dictemat.push({ key: -1, text: "Proszę wybrać", value: -1 })
        if (response.data.length > 0) {
          response.data.sort((a, b) => b.cycle_id - a.cycle_id).forEach((data, i) => {
            dictemat.push({ key: i, text: data.name, value: JSON.stringify(data) })
            //dictemat.push({ key: i, text: data.Login, value: data.Login })
          })

        }


        setTournamentCycle(dictemat);

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      // setloading(false)
    });
  }

  const submitAddCykl = e => {
    e.preventDefault();
    ConnectWS("/generator/createCycle", authContext.token, { cycle_name: formAddCykl.cykl.trim() }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {
        //setRowDataTournament(response.data)
        addToast(response.data.message, { appearance: 'success' });
        getCycles()
        //getTournaments()
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }



    });



  }

  const submitRenameCycle = e => {
    e.preventDefault();


    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz zmienić nazwę cyklu?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => renameCycle()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function renameCycle() {
      ConnectWS("/generator/renameCycle ", authContext.token, { new_cycle_name: formRenameCykl.cyklName.trim(), cycle_id: formRenameCykl.cyklId }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          getCycles();
          setformRenameCykl({
            ...formRenameCykl,
            cyklName: ''
          });
          showSubTournament1(formAddCyklSub.subtournament_name, formAddCyklSub.cycle_id)

        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }



      });
    }






  }




  const ColourCellRenderer = props => props.value === 0 ? <span>{packageColor(1)}BRĄZOWY</span> : props.value === 1 ? <span>{packageColor(2)}SREBRNY</span> : <span>{packageColor(3)}ZŁOTY</span>;


  function ChangeTournamentBox(data) {

    ConnectWS("/generator/getSubtournaments", authContext.token, { tournament_id: data.value }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name, value: data.subtournament_id })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })
        setSubTournament(dictemat);

        if (dictemat.length === 1) {
          setFormAddCyklSub({ ...formAddCyklSub, tournament_id: data.value, subtournament_id: dictemat[0].value })
        } else {
          setFormAddCyklSub({ ...formAddCyklSub, tournament_id: data.value })
        }
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  async function getDataGroupSubt(cycle_id, subtournament_name) {
    let tokendata1 = "Bearer " + authContext.token;
    axios.defaults.headers.common['Authorization'] = tokendata1;
    const res = await axios.post("/generator/getCycleSubtournament", { cycle_id: cycle_id, subtournament_name: subtournament_name }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res) {
      let dictemat = []
      dictemat.length = 0
      for (const res1row of res.data) {
        dictemat.push({ ...res1row, urlCycleAll: "all" })
      }
      return dictemat
    } else {
      return null
    }
  }

  async function getCycleSubtournaments2(cycle_id) {
    let tokendata1 = "Bearer " + authContext.token;
    axios.defaults.headers.common['Authorization'] = tokendata1;
    const res = await axios.post("/generator/getCycleSubtournaments", { cycle_id: cycle_id }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res) {
      let restmp = []
      res.data.forEach((data, i) => {
        if (data.struct_confirmed === 1) {
          restmp.push(data)
        }
      })
      return restmp
    } else {
      return null
    }
  }


  async function showSubTournament(data) {

    if (data.value !== 0) {
      let cycle_idtmp
      if (data.value === "-1" || data.value === -1) {
        cycle_idtmp = data.value
      } else {
        cycle_idtmp = JSON.parse(data.value).cycle_id
      }

      setcountSubtournamentAnalisis({ value: JSON.parse(data.value).count_subtournaments, newValue: JSON.parse(data.value).count_subtournaments })
      // jeżeli turniej jest grupowany to nie wyświetlamy wszystkie kategorie przypisane
      if (JSON.parse(data.value).group_subtournaments === 1) {
        setcycleIsGrouped({ cycle_id: cycle_idtmp, group_subtournaments: true })
        setFormAddCyklSub({ ...formAddCyklSub, cycle_id: data.value, subtournament_id: 0, tournament_id: 0, subtournament_name: "" })
        setformRenameCykl({ ...formRenameCykl, cyklId: cycle_idtmp })
        setRowDataCykle(null)

        let res1 = await getCycleSubtournaments2(cycle_idtmp)

        if (res1) {

          let dictemat = []
          dictemat.length = 0
          for (const res1row of res1) {
            let ret = await getDataGroupSubt(res1row.cycle_id, res1row.name)
            dictemat.push(...ret);

          }
          setRowDataCykle(dictemat)

        }


        // cykle nie jest grupowany

      } else {
        setcycleIsGrouped({ cycle_id: cycle_idtmp, group_subtournaments: false })
        setRowDataCykle(null)
        ConnectWS("/generator/getCycleSubtournaments", authContext.token, { cycle_id: cycle_idtmp }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);
          } else if (response && response.status && Number(response.status) === 200) {

            let dictemat = []
            dictemat.length = 0
            dictemat.push({ key: -1, text: "Proszę wybrać", value: 0 })
            response.data.forEach((data, i) => {
              if (data.struct_confirmed === 1) {
                dictemat.push({ key: i, text: data.name, value: data.name })
              }

              //dictemat.push({ key: i, text: data.Login, value: data.Login })
            })
            setSubTournament(dictemat);
            setformRenameCykl({ ...formRenameCykl, cyklId: cycle_idtmp })


            if (dictemat.length === 2) {
              setFormAddCyklSub({ ...formAddCyklSub, cycle_id: data.value, subtournament_id: 0, tournament_id: 0, subtournament_name: dictemat[1].value })
              showSubTournament1(dictemat[1].value, cycle_idtmp)
            } else {
              setFormAddCyklSub({ ...formAddCyklSub, cycle_id: data.value, subtournament_id: 0, tournament_id: 0, subtournament_name: "" })
            }
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //console.log(response);
          }



        });
      }
    } else {
      setSubTournament(null)
      setRowDataCykle(null)
    }
  }
  function showSubTournament1(data, cycle_id) {

    let cycle_id_val = cycle_id > 0 ? cycle_id : JSON.parse(cycle_id).cycle_id
    if (data !== 0 && cycle_id_val) {

      ConnectWS("/generator/getCycleSubtournament", authContext.token, { cycle_id: cycle_id_val, subtournament_name: data }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          setRowDataCykle(response.data)
        } else {
          //addToast(response, { appearance: 'error' });
          setRowDataCykle(null)
          //console.log(response);
        }



      });
    } else {
      setRowDataCykle(null)
    }
  }

  function changeCheckBoxArch(d) {
    setcheckboxarch(d.checked)
    getTournaments(d.checked)
    getActivities(d.checked)
  }


  function cycleChangeGroupSubtournaments(data, value) {

    ConnectWS("/generator/cycleChangeGroupSubtournaments", authContext.token, { cycle_id: data.cycle_id, group_subtournaments: value }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {
        setcycleIsGrouped({ cycle_id: data.cycle_id, group_subtournaments: value })
        getCycles()
        setformRenameCykl({ ...formRenameCykl, cyklId: -1 })
        addToast(response.data.message, { appearance: 'success' });
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }



    });
  }


  // Treningi
  const [gridApiActivities, setGridApiActivities] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApiActivities, setGridColumnApiActivities] = useState(null);// eslint-disable-line no-unused-vars
  const [rowDataActivities, setRowDataActivities] = useState(null);
  const [activity_id, setactivity_id] = useState(null);
  const [AddMyActivitiesOpen, setAddMyActivitiesOpen] = useState(false);


  const gridRefActivities = useRef();

  const onGridReadyActivities = (params) => {
    setGridApiActivities(params.api)
    setGridColumnApiActivities(params.columnApi)

  };

  function getActivities(checkboxarchTMP) {
    ConnectWS("/activity/getActivities", authContext.token, { skip_archived: checkboxarchTMP }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        setRowDataActivities(response.data)

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

        //console.log(response);
      }



    });
  }

  const rowCanceled = props => {
    return props.data.canceled !== 0 ? <Icon name='check' color="green" style={{ fontSize: 16 }} title="TAK" /> : <Icon name='x' style={{ fontSize: 16 }} color="grey" title="NIE" />
  };

  const rowCountM = props => {
    if (Number(props.data.confirmed_members) === Number(props.data.total_members)) {
      return <>{props.data.total_members} / {props.data.members_limit}</>
    } else {
      return <>{props.data.confirmed_members} / {props.data.total_members} / {props.data.members_limit}</>
    }

  };



  const rowEditActivities = props => {

    return <div style={{ cursor: "pointer" }} onClick={() => setactivity_id(props.data["activity_id"])} ><Icon style={{ fontSize: 16 }} name='edit' title="Przejdź do zajęć" /></div>
  };

  const rowDelActivities = props => {
    const rowDelQ = () => {
      confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz skasować zajęcia: ' + props.data["name"],
        buttons: [
          {
            label: 'Tak',
            onClick: () => delActivities(props.data["activity_id"])
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });

      function delActivities(activity_id) {
        ConnectWS("/activity/removeActivity", authContext.token, { activity_id: activity_id }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);
          } else if (response && response.status && Number(response.status) === 200) {
            //setRowDataTournament(response.data)
            addToast(response.data.message, { appearance: 'success' });
            getActivities(checkboxarch)
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //console.log(response);
          }



        });
      }


    };
    return <div style={{ cursor: "pointer" }} onClick={rowDelQ} ><Icon name='trash' style={{ fontSize: 16 }} color="red" title="Usuń rozgrywki" /></div>
  };


  const rowEditTArchivedActivity = props => {
    const rowEditTArchivedQActivity = () => {

      ConnectWS("/activity/archiveActivity", authContext.token, { activity_id: props.data.activity_id, archived: props.data.archived === 1 ? 0 : 1 }, function (response, logout) {
        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          getActivities(props.data.skip_archived)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }


    return <div style={{ cursor: "pointer" }} onClick={rowEditTArchivedQActivity} > {props.data.archived === 1 ? <Icon name='archive' color="grey" style={{ fontSize: 16 }} title="Przywrócić" /> : <Icon name='archive' style={{ fontSize: 16 }} color="green" title="Zarchiwizuj" />}</div>
  };




  // Udostępnione Rozgrywki
  const [gridApiSharedTournaments, setGridApiSharedTournaments] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApiSharedTournaments, setGridColumnApiSharedTournaments] = useState(null);// eslint-disable-line no-unused-vars
  const [rowDataSharedTournaments, setRowDataSharedTournaments] = useState(null);



  const gridRefSharedTournaments = useRef();



  const onGridReadySharedTournaments = (params) => {
    setGridApiSharedTournaments(params.api)
    setGridColumnApiSharedTournaments(params.columnApi)

  };

  function getSharedTournaments() {
    ConnectWS("/generator/getSharedTournaments", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        setRowDataSharedTournaments(response.data)

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

        //console.log(response);
      }



    });
  }

  const panes = [
    {
      menuItem: { key: 0, icon: 'winner', content: 'Moje rozgrywki' }, pane: <Tab.Pane>

        <Label color='red' ribbon>
          <Icon name='winner' /> Rozgrywki
        </Label>

        <Message size='mini'>
          <Checkbox style={{ marginRight: 20 }} checked={checkboxarch} onChange={(e, d) => changeCheckBoxArch(d)} label='Ukryj zarchiwizowane' />     Archiwizacja rozgrywek sprowadza się do ukrycia ich <u>tylko</u> z poniższej listy. W dowolnym momencie możliwe jest przywrócenie zarchiwizowanej rozgrywki.
        </Message>

        <AGGridActivity quickSearchHidden={false} gridApi={gridApiTournament} gridColumnApi={gridColumnApiTournament} gridApiRef={gridRefAG} />
        <div ref={gridRef} className="ag-theme-balham" style={{ position: "relative", minHeight: defHeightList, height: height - 400, width: "100%" }}>


          <AgGridReact
            ref={gridRefAG}
            pagination={true}
            animateRows={true}
            defaultColDef={{

              editable: false,
              sortable: true,
              minWidth: 100,
              filter: false,
              resizable: true,
              floatingFilter: false,
              flex: 1,
            }}
            frameworkComponents={{
              rowDel: rowDelTournament,
              rowEdit: rowEditTournament,
              rowEditTArchived: rowEditTArchived,
              rowRO: rowRO,
              rowDisableView: rowDisableView,
              rowCopy: rowCopy,
            }}
            localeText={AG_GRID_LOCALE_PL}
            onGridReady={onGridReadyTournament}
            rowData={rowDataTournament}
          ><AgGridColumn cellRenderer='rowEditTArchived' headerName="Archiwizacja" minWidth={100}
            maxWidth={110}
            width={100}
            flex={0}
            resizable={false}
            sortable={false}
            editable={false}
            filter={false} />
            <AgGridColumn field="start_date" headerName="Dzień" maxWidth={140} minWidth={140} resizable={false}
              width={140} filter="agMultiColumnFilter" />

            {authContext && authContext.user && authContext.user.subscription_info.subscription_active ?
              <AgGridColumn cellRenderer='rowDisableView' headerName="Widoczność rozgrywek na dedykowanej stronie " minWidth={70}
                maxWidth={310}
                width={70}
                flex={0}
                resizable={true}
                sortable={false}
                editable={false}
                filter={false} />
              : ""}
            <AgGridColumn field="name" width={500} flex={0} headerName="Nazwa rozgrywek" filter="agMultiColumnFilter" />
            <AgGridColumn field="cycles" headerName="Cykl" filter="agMultiColumnFilter" />

            <AgGridColumn field="points_calc_system_name" maxWidth={140} minWidth={140} headerName="Rodzaj sportu" filter="agMultiColumnFilter" />

            <AgGridColumn cellRenderer='rowRO' headerName="Rejestracja Online" minWidth={60}
              maxWidth={140}
              width={140}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='rowEdit' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />
            <AgGridColumn cellRenderer='rowCopy' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />
            <AgGridColumn field="tournament_free" headerName="Pakiet" cellRenderer={ColourCellRenderer} maxWidth={120}
              width={120} minWidth={120} resizable={false} filter="agMultiColumnFilter" />


            <AgGridColumn cellRenderer='rowDel' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />


          </AgGridReact>
        </div>



      </Tab.Pane>
    },
    {
      menuItem: { key: 1, icon: 'cogs', content: 'Cykle' }, pane: <Tab.Pane>

        <Label color='orange' ribbon>
          <Icon name='cogs' />

          Cykle
        </Label>
        <Message size='tiny'>
          <p>
            Możesz utworzyć cykl, dzięki któremu będziesz mógł połączyć kilka rozgrywek w jeden cykl. Suma punktów zdobytych w poszczególnych rozgrywkach zostanie podsumowana.
          </p>
          <p>
            Warunki:</p>
          <List>
            {cycleIsGrouped.group_subtournaments ?
              <List.Item>
                <List.Icon name='info circle' />
                <List.Content>Wszystkie kategorie w wybranym cyklu sa łączone.</List.Content>
              </List.Item>
              :
              <List.Item>
                <List.Icon name='info circle' />
                <List.Content>Wszystkie kategorie zostaną zsumowane po nazwie.</List.Content>
              </List.Item>
            }
            <List.Item>
              <List.Icon name='eye' color="green" />
              <List.Content>Włączone sumowanie punktów z tych rozgrywek do całego cyklu.</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='eye' color="red" />
              <List.Content>Wyłączone sumowanie punktów z tych rozgrywek do całego cyklu.</List.Content>
            </List.Item>
          </List>
        </Message>

        <Form style={{ marginTop: 10 }} size='tiny' >
          <Form.Group inline widths='equal'>

            <Form.Select
              fluid
              options={TournamentCycle}
              search
              label="Cykl"
              value={formAddCyklSub.cycle_id}
              onChange={(e, data) => showSubTournament(data)}
              placeholder='Proszę wybrać...'
            />
            {!cycleIsGrouped.group_subtournaments ?
              <Form.Select
                fluid
                label="Kategoria"
                search
                options={SubTournament}
                value={formAddCyklSub.subtournament_name}
                onChange={(e, data) => {
                  setFormAddCyklSub({ ...formAddCyklSub, subtournament_name: data.value })
                  showSubTournament1(data.value, formAddCyklSub.cycle_id)

                }}
                placeholder='Proszę wybrać...'
              />
              : ""}
          </Form.Group>
        </Form>
        <Checkbox label='Wszystkie kategorie w cyklu są sumowane' disabled={formRenameCykl.cyklId < 1 ? true : false} checked={cycleIsGrouped.group_subtournaments} onChange={(e, d) => cycleChangeGroupSubtournaments(cycleIsGrouped, d.checked)} />

        <Form style={{ marginTop: 10 }} size='tiny' onSubmit={submitRenameCycle}>
          <Form.Group inline widths='equal'>

            <Form.Field
              width={12}
              control={Input}
              required
              disabled={formRenameCykl.cyklId === 0 ? true : false}
              placeholder='Nowa nazwa cyklu'
              value={formRenameCykl.cyklName}
              onChange={(e, data) => setformRenameCykl({ ...formRenameCykl, cyklName: removeSpecialChars(removeEmojis((data.value))) })}
            />
            <Form.Button size='tiny' disabled={formRenameCykl.cyklId < 1 ? true : false} fluid>ZMIEN NAZWE CYKLU</Form.Button>
          </Form.Group>


        </Form>

        <Segment style={{ fontSize: 14, lineHeight: "14px" }}>

          <div style={{ display: "inline" }}>
            <Input style={{ width: 70 }} type='number' disabled={formRenameCykl.cyklId < 1 ? true : false} value={countSubtournamentAnalisis.newValue} size='mini' onChange={(e, d) => setcountSubtournamentAnalisis({ ...countSubtournamentAnalisis, newValue: d.value })} /> {countSubtournamentAnalisis.newValue !== countSubtournamentAnalisis.value ? <Icon className='cursorPointer' title="zapisz" color='orange' onClick={() => savecountSubtournamentAnalisis(formRenameCykl.cyklId)} inverted bordered name="save" /> : ""}

          </div>
          <div style={{ marginLeft: 15, display: "inline" }}>
            0 - opcja jest wyłaczona i sprawdzane sa wszystkie turnieje. Jeżeli ustawisz wartość większą niż 0, wówczas wprowadzona wartość np 3 spowoduje wybranie 3 najlepszych turniejów dla każdego uczestnika na podstawie których bedzie sumowana punktacja w cyklu.

          </div>


        </Segment>

        <br />
        <AGGridActivity quickSearchHidden={false} gridApi={gridApiCykle} gridColumnApi={gridColumnApiCykle} gridApiRef={gridRefCykleAG} />
        <div ref={gridRefCykle} className="ag-theme-balham" style={{ position: "relative", marginTop: 10, height: defHeightList, width: "100%" }}>

          <AgGridReact
            ref={gridRefCykleAG}
            pagination={true}
            animateRows={true}
            defaultColDef={{
              editable: false,
              sortable: true,
              minWidth: 100,
              filter: false,
              resizable: true,
              floatingFilter: false,
              flex: 1,
            }}
            frameworkComponents={{
              rowDel: rowDelCykle,
              rowEdit: rowEditCykle,
              urlCycle: urlCycle

            }}
            localeText={AG_GRID_LOCALE_PL}
            onGridReady={onGridReadyCykle}
            rowData={rowDataCykle}
          >

            <AgGridColumn field="cycleName" headerName="Cykl" filter="agMultiColumnFilter" />
            <AgGridColumn field="tournamentName" headerName="Rozgrywki" filter="agMultiColumnFilter" />
            <AgGridColumn field="tournamentStartDate" headerName="Data" filter="agMultiColumnFilter" />
            <AgGridColumn field="subtournamentName" headerName="Kategoria" filter="agMultiColumnFilter" />
            <AgGridColumn field="subtournamentType" headerName="Typ rozgrywek" filter="agMultiColumnFilter" />
            <AgGridColumn cellRenderer='urlCycle' headerName="Strona cyklu" />
            <AgGridColumn cellRenderer='rowEdit' headerName="Analiza" minWidth={70}
              maxWidth={70}
              width={70}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />
            <AgGridColumn cellRenderer='rowDel' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

          </AgGridReact>
        </div>

        <br />
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={16}>


              <Label color='grey' ribbon>
                <Icon name='add' /> Dodaj cykl

              </Label>
              <Form style={{ margin: 10 }} size='tiny' onSubmit={submitAddCykl}>
                <Form.Field
                  control={Input}
                  required
                  label="Nazwa cyklu"
                  placeholder='Nazwa cyklu'
                  value={formAddCykl.cykl}
                  onChange={(e, data) => setFormAddCykl({ ...formAddCykl, cykl: removeSpecialChars(removeEmojis((data.value))) })}
                />
                <Form.Button size='tiny' fluid>DODAJ CYKL</Form.Button>
              </Form>

            </Grid.Column>
            <Grid.Column width={16}>

              <Label color='grey' ribbon>
                <Icon name='trash' /> Kasuj cykl

              </Label>
              <Form style={{ margin: 10 }} size='tiny'>
                <Form.Select
                  fluid
                  options={TournamentCycle}
                  search
                  label="Nazwa cyklu"
                  value={formAddCyklSub.cycle_id}
                  onChange={(e, data) => setFormAddCyklSub({ ...formAddCyklSub, cycle_id: data.value })}
                  placeholder='cykl'
                />
                <Form.Button fluid size='tiny' disabled={formAddCyklSub.cycle_id === -1 ? true : false} onClick={(e, data) => rowDelTournamentCykl()} >KASUJ CYKL</Form.Button>
              </Form>

            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Label color='grey' ribbon>
          <Icon name='add circle' /> Przypisz rozgrywki do cyklu

        </Label>
        <Form style={{ margin: 10 }} size='tiny' onSubmit={submitAddTournamenttoCykl}>
          <Form.Group inline widths='equal'>

            <Form.Select
              fluid
              options={TournamentCycle}
              search
              label="Cykl"
              value={formAddCyklSub.cycle_id}
              onChange={(e, data) => setFormAddCyklSub({ ...formAddCyklSub, cycle_id: data.value })}
              placeholder='cykl'
            />

            <Form.Select
              fluid
              search
              label="Rozgrywki"
              options={Tournament}
              value={formAddCyklSub.tournament_id}
              onChange={(e, data) => ChangeTournamentBox(data)}
              placeholder='rozgrywki'
            />

            <Form.Select
              fluid
              search
              label="Kategoria"
              placeholder='kategoria'
              options={SubTournament}
              value={formAddCyklSub.subtournament_id}
              onChange={(e, data) => setFormAddCyklSub({ ...formAddCyklSub, subtournament_id: data.value })}

            />

          </Form.Group>
          <Form.Button fluid size='tiny' >PRZYPISZ KATEGORIĘ DO CYKLU</Form.Button>

        </Form>


      </Tab.Pane>
    }, {
      menuItem: { key: 3, icon: 'handshake', content: 'Moje zajęcia/treningi' }, pane: <Tab.Pane>
        <Label color='blue' ribbon>
          <Icon name='handshake' /> Zajęcia/treningi
        </Label>


        <MyActivities activity_id={activity_id} setactivity_id={setactivity_id} />
        <AddMyActivities AddMyActivitiesOpen={AddMyActivitiesOpen} setAddMyActivitiesOpen={setAddMyActivitiesOpen} />
        <ButtonGroup widths='3'>
          <Button onClick={() => history.push("/zapisy-na-treningi-online")}>ZOBACZ OPIS</Button>
          <ButtonOr text='lub' />


          <Button size='mini' disabled={authContext && authContext.user && (authContext.user.subscription_info.subscription_extended === -1 || authContext.user.subscription_info.subscription_extended === 1) ? false : true} onClick={() => setAddMyActivitiesOpen(true)} fluid color='brown'>DODAJ ZAJĘCIA</Button>
        </ButtonGroup>
        {authContext && authContext.user && (authContext.user.subscription_info.subscription_extended === -1 || authContext.user.subscription_info.subscription_extended === 1) ? "" : <Message size='mini'>
          Funkcjonalność dostępna tylko dla subskrybentów, dostęp w pakiecie <span className='tllink cursorPointer' onClick={() => history.push("/cennik")}>diamentowym Lite</span> lub <span className='tllink cursorPointer' onClick={() => history.push("/cennik")}>diamentowym Plus</span>.
        </Message>}
        <Message size='mini'>
          <Checkbox style={{ marginRight: 20 }} checked={checkboxarch} onChange={(e, d) => changeCheckBoxArch(d)} label='Ukryj zarchiwizowane' />     Archiwizacja rekordów sprowadza się do ukrycia ich <u>tylko</u> z poniższej listy. W dowolnym momencie możliwe jest przywrócenie zarchiwizowanej rozgrywki.
        </Message>

        <AGGridActivity quickSearchHidden={false} gridApi={gridApiActivities} gridColumnApi={gridColumnApiActivities} gridApiRef={gridRefActivities} />
        <div className="ag-theme-balham" style={{ position: "relative", minHeight: defHeightList, height: height - 400, width: "100%" }}>


          <AgGridReact
            ref={gridRefActivities}
            pagination={true}
            animateRows={true}
            defaultColDef={{

              editable: false,
              sortable: true,
              minWidth: 100,
              filter: false,
              resizable: true,
              floatingFilter: false,
              flex: 1,
            }}
            frameworkComponents={{
              rowDelActivities: rowDelActivities,
              rowEditActivities: rowEditActivities,
              rowEditTArchivedActivity: rowEditTArchivedActivity,
              rowRO: rowRO,
              rowCanceled: rowCanceled,
              rowCountM: rowCountM,
              rowCloneA: rowCloneA
            }}
            localeText={AG_GRID_LOCALE_PL}
            onGridReady={onGridReadyActivities}
            rowData={rowDataActivities}
          ><AgGridColumn cellRenderer='rowEditTArchivedActivity' headerName="Archiwizacja" minWidth={100}
            maxWidth={110}
            width={100}
            flex={0}
            resizable={false}
            sortable={false}
            editable={false}
            filter={false} />
            <AgGridColumn field="activity_start_date" headerName="Początek zajęć" maxWidth={140} minWidth={140} resizable={false} width={140} filter="agMultiColumnFilter" />
            <AgGridColumn field="activity_stop_date" headerName="Koniec zajęć" maxWidth={140} minWidth={140} resizable={false} width={140} filter="agMultiColumnFilter" />
            <AgGridColumn field="register_stop_date" headerName="Koniec zapisów" maxWidth={140} minWidth={140} resizable={false} width={140} filter="agMultiColumnFilter" />

            <AgGridColumn field="name" headerName="Nazwa" filter="agMultiColumnFilter" />


            <AgGridColumn cellRenderer='rowCountM' headerName="Uczestników" minWidth={60}
              maxWidth={140}
              width={140}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />


            <AgGridColumn cellRenderer='rowCanceled' headerName="Odwołane" minWidth={60}
              maxWidth={140}
              width={140}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='rowEditActivities' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='rowCloneA' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='rowDelActivities' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />


          </AgGridReact>
        </div>


      </Tab.Pane>
    }, {
      menuItem: { key: 4, icon: 'share', content: 'Udostępnione rozgrywki' }, pane: <Tab.Pane>
        <Label color='blue' ribbon>
          <Icon name='share' /> Udostępnione rozgrywki
        </Label>



        <AGGridActivity quickSearchHidden={false} gridApi={gridApiActivities} gridColumnApi={gridColumnApiActivities} gridApiRef={gridRefActivities} />
        <div className="ag-theme-balham" style={{ position: "relative", minHeight: defHeightList, height: height - 400, width: "100%" }}>


          <AgGridReact
            ref={gridRefSharedTournaments}
            pagination={true}
            animateRows={true}
            defaultColDef={{

              editable: false,
              sortable: true,
              minWidth: 100,
              filter: false,
              resizable: true,
              floatingFilter: false,
              flex: 1,
            }}
            frameworkComponents={{
              rowEdit: rowEditTournament,
            
            }}
            localeText={AG_GRID_LOCALE_PL}
            onGridReady={onGridReadySharedTournaments}
            rowData={rowDataSharedTournaments}
          >

            <AgGridColumn field="tournament_name" headerName="Nazwa rozgrywek" filter="agMultiColumnFilter" />
            <AgGridColumn field="organizer_name" headerName="Organizator" filter="agMultiColumnFilter" />
            <AgGridColumn field="start_date" headerName="Rozpoczęcie rozgrywek" filter="agMultiColumnFilter" />
            <AgGridColumn field="stop_date" headerName="Zakończenie rozgrywek" filter="agMultiColumnFilter" />
            <AgGridColumn field="points_calc_system_name" maxWidth={140} minWidth={140} headerName="Rodzaj sportu" filter="agMultiColumnFilter" />

       

            <AgGridColumn cellRenderer='rowEdit' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />


          </AgGridReact>
        </div>


      </Tab.Pane>
    }
  ]

  function getSubscruptionCard() {
    try {
      if (!checkDateStartBeforeNow(addDateToDate(authContext.user.subscription_info.subscription_to, -5))) {
        return <Segment style={{ backgroundColor: "#f2f2f2" }}><Grid stackable>
          <Grid.Row stretched>
            <Grid.Column width={width > 1400 ? 10 : 6}>
              <Message icon error>

                {authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1 ? packageColor(5) : authContext.user.subscription_info.subscription_extended === 1 ? packageColor(4) : packageColor(authContext.user.subscription_info.subscription_extended)}
                <Message.Content>
                  <Message.Header> TWOJA SUBSKRYPCJA NIEBAWEM WYGAŚNIE</Message.Header>
                  SUBSKRYPCJA AKTYWNA DO: {authContext.user.subscription_info.subscription_to}
                </Message.Content>
              </Message>
            </Grid.Column>
            <Grid.Column width={width > 1400 ? 4 : 7}>
              <CountDownTimer endDate={authContext.user.subscription_info.subscription_to} textValue="DO KOŃCA SUBSKRYPCJI" textValueFinish="" beautifulText="0" />

            </Grid.Column>
            <Grid.Column width={width > 1400 ? 2 : 3}>
              {checkiscompanypay() === 1 ?
                <BuyProductCompany payable_service_id={null} buyProductType={2} isToken={false} messageProduct={"Subskrypcja"} isDemo={isDemo} issmall={true} btnname={"PRZEDŁUŻ " + authContext.user.subscription_info.subscription_price + " PLN BRUTTO"} price={authContext.user.subscription_info.subscription_price} />
                :
                <BuySubscription isDemo={isDemo} issmall={true} btnname={"PRZEDŁUŻ " + authContext.user.subscription_info.subscription_price + " PLN"} price={authContext.user.subscription_info.subscription_price} subscription_type={authContext.user.subscription_info.subscription_extended === 1 ? 2 : 1} />
              }

            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Segment>
      }
    } catch (e) {
      return ""
    }



  }


  return (
    <>
      {authContext.user.subscription_info && authContext.user.subscription_info.subscription_active === true ? getSubscruptionCard() : ""}
      <Tab panes={panes} activeIndex={activeIndex} onTabChange={(e, { activeIndex }) => setactiveIndex(activeIndex)} renderActiveOnly={false} />
      <CloneTournament cloneTournamentOpenModal={cloneTournamentOpenModal} setcloneTournamentOpenModal={setcloneTournamentOpenModal} />
      <CloneActivities cloneActivitiesOpenModal={cloneActivitiesOpenModal} setcloneActivitiesOpenModal={setcloneActivitiesOpenModal} />
    </>
  )
}

export default MyTournaments;