import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Dropdown, Form, FormField, Grid, Header, Icon,  Input, Label, Message, MessageHeader, Modal } from 'semantic-ui-react';
import { ConnectWS } from '../ConnectWS';
import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { Markup } from 'interweave';
import GetEditorTG from './GetEditorTG';
import { confirmAlert } from 'react-confirm-alert';
import { getZoomT, loadingShow } from '../myfunctions';
import useWindowDimensions from '../hooks/useWindowDimensions';


function TournamentRules({ tournamentJsonRoot, tournament_id, subtournament_id, onlyView }) {
    const authContext = useContext(AuthContext);
    const { addToast } = useToasts();
    const [rte, setrte] = useState("");
    const [subTList, setsubTList] = useState([]);
    const [loading, setloading] = useState(false);
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [rulesData, setrulesData] = useState([]);

    const [modalRules, setmodalRules] = useState(null)

    useEffect(() => {
        if (tournamentJsonRoot) {
            if (tournamentJsonRoot.subtournaments && tournamentJsonRoot.subtournaments.length > 0) {
                let arr = []
                arr.push({ key: 1000, text: "WSZYSTKIE KATEGORIE", value: 0 })
                tournamentJsonRoot.subtournaments.forEach((row, i) => {
                    arr.push({ key: i, text: row.name, value: row.subtournament_id })

                })
                setsubTList(arr)
            }

            if (tournamentJsonRoot.tournament_rules) {
                let arr1 = []
                if (tournamentJsonRoot.tournament_rules && tournamentJsonRoot.tournament_rules.length > 0) {

                    tournamentJsonRoot.tournament_rules.forEach((row, i) => {
                        if (row.subtournament_id === subtournament_id || row.subtournament_id === 0 || subtournament_id === 0) {
                            arr1.push(row)
                        }


                    })
                }
                setrulesData(arr1)

            }
        }

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [tournamentJsonRoot])// eslint-disable-line react-hooks/exhaustive-deps


    function getRuleData(row, i) {
        console.log(row)
        if (row.isNew) {
            setmodalRules({ row: row, i: i })
            setrte(row.rule)
        }
        else {
            setloading(true)
            ConnectWS("/generator/getTournamentRules", authContext.token, { tournament_id: tournament_id }, function (response, logout) {

                if (logout === 1) {
                    addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

                } else if (response && response.status && Number(response.status) === 200) {
                    //setRowDataTournament(response.data)
                    if (response.data.length > 0) {
                        let ttt
                        response.data.forEach((row1) => {
                            if (row1.subtournament_id === row.subtournament_id) {
                                ttt = row1
                                return
                            }

                        }
                        )
                        setmodalRules({ row: ttt, i: i })
                        setrte(ttt.rule)
                    }


                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                    //console.log(response);
                }
                setloading(false)

            });
        }
    }

    function delRuleBody() {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować treść regulaminu?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => delRuleBodyacc()
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });


        function delRuleBodyacc() {
            setrte("")
        }
    }


    function changeDataRules(value, name) {
        let tmp = { ...modalRules }
        tmp.row[name] = value
        setmodalRules(tmp)
    }


    function addRowTab() {
        let tmp = [...rulesData]
        tmp.push({ tournament_id: tournament_id, subtournament_id: 0, name: "Regulamin", rule: "", url: null, isNew: true })
        setrulesData(tmp)
    }

    function removeRowTab(i) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować regulamin?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeRowTabACC(i)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });


        function removeRowTabACC(i) {

            ConnectWS("/generator/removeTournamentRule", authContext.token, { tournament_id: tournament_id, subtournament_id: rulesData[i].subtournament_id }, function (response, logout) {

                if (logout === 1) {
                    addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)

                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                    //console.log(response);
                }


            });

        }
    }


    function saveData() {
        console.log(modalRules.row)
        ConnectWS("/generator/addTournamentRule", authContext.token, { tournament_id: tournament_id, subtournament_id: modalRules.row.subtournament_id, name: modalRules.row.name, rule: rte, url: modalRules.row.url }, function (response, logout) {

            if (logout === 1) {
                addToast("Brak zalogowanego użytkownika", { appearance: 'info' });

            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }


        });
    }


    return (
        rulesData.length > 0 || onlyView === 0 ?
            <div >
                <Message icon size="mini" >
                    <Icon name='file alternate' />
                    <Message.Content>
                        <MessageHeader>  {t("WidokTurnieju.regulamin")}
                            {onlyView === 1 ? "" : <Icon name="add" onClick={() => addRowTab()} color='orange' style={{ marginLeft: 10 }} className='cursorPointer' />}
                        </MessageHeader>

                        {rulesData.map((row, i) => <div key={i} style={{ marginBottom: width < 900 ? 5 : 2, marginTop: width < 900 ? 5 : 2 }}>
                            <Icon name="linkify" style={{ marginRight: 7 }} />
                            {row.url && onlyView === 1 ? <a href={row.url} className='tllink' target="_blank" rel="noopener noreferrer">{row.name}</a> : <span className='tllink cursorPointer' onClick={() => getRuleData(row, i)}>{row.name} {row.isNew ? " (niezapisany)" : ""}</span>}
                            {onlyView === 1 ? "" : <Icon name="trash" onClick={() => removeRowTab(i)} color='grey' style={{ marginLeft: 10 }} className='cursorPointer' />}
                        </div>)}

                    </Message.Content>
                </Message>
                <Modal
                    open={modalRules ? true : false}
                    centered={width < 900 ? false : true}
                    size='large'
                    style={{ zoom: getZoomT(width) }}
                >
                    <Modal.Header>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={16} verticalAlign="middle">
                                    <Header as='h4' textAlign='center'>
                                        <Icon name='info circle' />
                                        <Header.Content>{modalRules ? modalRules.row.name : ""}</Header.Content>
                                    </Header>
                                </Grid.Column>
                               
                            </Grid.Row>
                        </Grid>

                    </Modal.Header>
                    <Modal.Content style={{ backgroundColor: "#f5f5f5" }} >

                        {loading ? loadingShow() : <>
                            {onlyView === 1 ? <Markup content={modalRules ? modalRules.row.rule : "?"} /> : <div>
                                <Message>
                                    Wprowadź opis regulaminu lub dodaj link do regulaminu, jeżeli regulamin dotyczy konkretnej kategorii proszę ją wybrać z listy.
                                </Message>

                                <Form>
                                    <FormField>
                                        <label>Nazwa regulaminu</label>
                                        <Input fluid value={modalRules ? modalRules.row.name : ""} onChange={(e, d) => changeDataRules(d.value, "name")} placeholder='Nazwa regulaminu' />
                                    </FormField>
                                    <FormField>
                                        <label>Kategoria</label>
                                        <Dropdown

                                            style={{ marginTop: 10, marginBottom: 10 }}
                                            selection
                                            fluid
                                            options={subTList}
                                            onChange={(e, d) => changeDataRules(d.value, "subtournament_id")}
                                            value={modalRules ? modalRules.row.subtournament_id : ""}

                                        />
                                    </FormField>
                                    <FormField>
                                        <label>Dodaj link do regulaminu lub wklej opis poniżej</label>
                                        <Input type="url" style={{ marginTop: 10, marginBottom: 10 }} fluid icon='linkify' value={modalRules ? modalRules.row.url : ""} onChange={(e, d) => changeDataRules(d.value, "url")} placeholder='Link do regulaminu...' />
                                    </FormField>
                                </Form>
                                <Container textAlign='center' style={{ marginBottom: 10 }}><Label>LUB</Label></Container>
                                <GetEditorTG rte={rte} setrte={setrte} />
                                <Button.Group fluid size='tiny' >

                                    <Button onClick={() => {
                                        delRuleBody()
                                    }
                                    }>SKASUJ ZAWARTOŚĆ OPISOWĄ</Button>
                                </Button.Group>


                            </div>}
                        </>}
                    </Modal.Content>
                    <Modal.Actions>
                        {onlyView === 0 ?
                            <Button color='green' onClick={() => saveData()}>
                                {t("Zapisz").toUpperCase()}
                            </Button>
                            : ""}
                        <Button color='black' onClick={() => {
                            setmodalRules(null)
                            setrte("")
                        }}>
                            {t("Zamknij").toUpperCase()}
                        </Button>

                    </Modal.Actions>
                </Modal>
            </div>
            : ""
    )
}

export default TournamentRules;