import React from 'react';

const AuthContext = React.createContext({
    isAuthenticated: false,
    leagueAction:0,
    token: null,
    tokenPanel: null,
    user:null,
    modalInfo:null,
    gView:"",
    refreshView:0,
    liveIcon:0,
    darkMode: null,
    onlyOneColumn:false,
    showLoading:false,
    paymentBlock:false,
    refreshViewHeight:0,
    login:() => {},
    logout:() => {},
    changeToken:(val) => {},
    changeTokenPanel:(val) => {},
    changeUser:(val) => {},
    changeModalInfo:(val) => {},
    changeView:(val) => {},
    changeRefreshView:(val) => {},
    changeLiveIcon:(val) => {},
    changeDarkMode:(val) => {},
    changeOnlyOneColumn:(val) => {},
    changeShowLoading:(val) => {},
    changePaymentBlock:(val) => {},
    changeRefreshViewHeight:(val) => {},
});

export default AuthContext;