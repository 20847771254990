import { Grid, Icon, Table } from "semantic-ui-react";
import { checkDateBeforeDay, getStosunekPTK, ifNullDate } from "./myfunctions";
import { getValueData, getValueDataVer2 } from "./tools/StatsEx/StatsEx";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MyImageLazy from "./tools/MyImageLazy";

export function getTopXMembers(jsonData, jsonDataMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 1) {
                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , member1_id: element2.team1_member_id
                                , member2_id: element2.team2_member_id
                            })
                        }

                    });
                }
            });
        });



        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file, team_photo_file: element.team_photo_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_ratio: 0,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0

                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {
                    if (element.member_id === element1.member1_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.member_id === element1.member2_id) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_ratio = getStosunekPTK(total_points_winners, total_points_losers)
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small

            })
            return tmpMT.sort((a, b) => b.total_points_ratio - a.total_points_ratio
                || b.total_points_winners - a.total_points_winners
                || a.total_points_losers - b.total_points_losers
                || (b.total_points_winners_small - b.total_points_losers_small) - (a.total_points_winners_small - a.total_points_losers_small))
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}



export function getTopXPtkMembers(jsonData, jsonDataMembers) {
    try {
        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null) {
                            tmpM.push({ ...element2, details_score: null })
                        }

                    });
                }
            });
        });



        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_id: element.member_id, team_name: element.name, team_img: element.img_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points: 0, total_points_matches_single: 0, total_points_winners_single: 0, total_points_losers_single: 0,
                        total_points_matches_debel: 0, total_points_winners_debel: 0, total_points_losers_debel: 0

                    })
                });
            }

        });

        if (tmpMT.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            tmpMT.forEach(element => {
                let total_points = 0
                let total_points_matches_single = 0
                let total_points_winners_single = 0
                let total_points_losers_single = 0
                let total_points_matches_debel = 0
                let total_points_winners_debel = 0
                let total_points_losers_debel = 0

                tmpM.forEach(element1 => {

                    if (element.member_id === element1.team1_member_id && element1.type === 1) {
                        total_points_matches_single++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points += 1
                            total_points_winners_single++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers_single++
                        }

                    } else if ((element.member_id === element1.team1_member1_id || element.member_id === element1.team1_member2_id) && element1.type === 2) {
                        total_points_matches_debel++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points += 0.5
                            total_points_winners_debel++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers_debel++
                        }

                    } else if (element.member_id === element1.team2_member_id && element1.type === 1) {
                        total_points_matches_single++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers_single++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points += 1
                            total_points_winners_single++
                        }
                    } else if ((element.member_id === element1.team2_member1_id || element.member_id === element1.team2_member2_id) && element1.type === 2) {
                        total_points_matches_debel++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers_debel++
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points += 0.5
                            total_points_winners_debel++
                        }
                    }

                })
                element.total_points = total_points
                element.total_points_matches_debel = total_points_matches_debel
                element.total_points_losers_debel = total_points_losers_debel
                element.total_points_winners_debel = total_points_winners_debel
                element.total_points_matches_single = total_points_matches_single
                element.total_points_winners_single = total_points_winners_single
                element.total_points_losers_single = total_points_losers_single
            })

            return tmpMT.sort((a, b) => b.total_points - a.total_points)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}

export function getTopXDebelMembers(jsonData, jsonDataMembers, countMembers) {
    try {
        function getHashM(member1_id, member2_id) {
            return member1_id + "_" + member2_id
        }

        let tmpM = []
        // tworzymy tablicę wszystkich pojedynków
        jsonData.forEach(element => {
            element.matches.forEach(element1 => {
                if (element1.match_details_ex && element1.match_details_ex.matche_ts) {
                    element1.match_details_ex.matche_ts.forEach(element2 => {

                        if (element2.score_member1 !== null && element2.score_member2 !== null && element2.type === 2) {

                            tmpM.push({
                                score_member1: element2.score_member1
                                , score_member2: element2.score_member2
                                , t1_member1_id: element2.team1_member1_id
                                , t1_member2_id: element2.team1_member2_id
                                , t2_member1_id: element2.team2_member1_id
                                , t2_member2_id: element2.team2_member2_id
                                , t1_hash: element2.team1_member1_id + "_" + element2.team1_member2_id
                                , t2_hash: element2.team2_member1_id + "_" + element2.team2_member2_id
                            })
                        }

                    });
                }
            });
        });


        let tmpMT = []
        // lista membersów w drużynie
        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({
                        team_name: element.name, team_img: element.img_file, team_id: element.member_id,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        total_points_matches: 0, total_points_winners: 0, total_points_losers: 0, total_points_ratio: 0, total_points_winners_small: 0, total_points_losers_small: 0
                    })
                });
            }

        });

        // petla po zawodnikach deblowych z kasowaniem dubli same unikalne wartości
        let memberOfDebel = []
        tmpM.forEach(element => {
            if (!memberOfDebel.filter(function (item) { return item.thash === getHashM(element.t1_member1_id, element.t1_member2_id) || item.thash === getHashM(element.t1_member2_id, element.t1_member1_id) }).length > 0) {
                let member1 = tmpMT.filter(function (item) { return item.member_id === element.t1_member1_id; })
                let member2 = tmpMT.filter(function (item) { return item.member_id === element.t1_member2_id; })

                if (member1 && member1.length === 1 && member2 && member2.length === 1) {
                    memberOfDebel.push({
                        thash: element.t1_hash, member1_id: element.t1_member1_id, member2_id: element.t1_member2_id
                        , member1_name: member1[0].member_name, member1_img: member1[0].member_img
                        , member2_name: member2[0].member_name, member2_img: member2[0].member_img
                        , team_name: member1[0].team_name, team_img: member1[0].team_img, team_id: member1[0].team_id
                    })
                }

            }

            if (!memberOfDebel.filter(function (item) { return item.thash === getHashM(element.t2_member1_id, element.t2_member2_id) || item.thash === getHashM(element.t2_member2_id, element.t2_member1_id) }).length > 0) {
                let member1 = tmpMT.filter(function (item) { return item.member_id === element.t2_member1_id; })
                let member2 = tmpMT.filter(function (item) { return item.member_id === element.t2_member2_id; })
                if (member1 && member1.length === 1 && member2 && member2.length === 1) {
                    memberOfDebel.push({
                        thash: element.t2_hash, member1_id: element.t2_member1_id, member2_id: element.t2_member2_id
                        , member1_name: member1[0].member_name, member1_img: member1[0].member_img
                        , member2_name: member2[0].member_name, member2_img: member2[0].member_img
                        , team_name: member1[0].team_name, team_img: member1[0].team_img, team_id: member1[0].team_id

                    })
                }
            }


        });


        if (memberOfDebel.length > 0 && tmpM.length > 0) {
            // aktualizujemy pojedynki
            memberOfDebel.forEach(element => {
                let total_points_matches = 0
                let total_points_winners = 0
                let total_points_losers = 0
                let total_points_winners_small = 0
                let total_points_losers_small = 0

                tmpM.forEach(element1 => {

                    if (element.thash === getHashM(element1.t1_member1_id, element1.t1_member2_id) || element.thash === getHashM(element1.t1_member2_id, element1.t1_member1_id)) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_losers++
                            total_points_losers_small = total_points_losers_small + element1.score_member2
                            total_points_winners_small = total_points_winners_small + element1.score_member1
                        }

                    } else if (element.thash === getHashM(element1.t2_member1_id, element1.t2_member2_id) || element.thash === getHashM(element1.t2_member2_id, element1.t2_member1_id)) {
                        total_points_matches++
                        if (element1.score_member1 > element1.score_member2) {
                            total_points_losers++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        } else if (element1.score_member1 < element1.score_member2) {
                            total_points_winners++
                            total_points_winners_small = total_points_winners_small + element1.score_member2
                            total_points_losers_small = total_points_losers_small + element1.score_member1
                        }
                    }

                })
                element.total_points_matches = total_points_matches
                element.total_points_winners = total_points_winners
                element.total_points_losers = total_points_losers
                element.total_points_winners_small = total_points_winners_small
                element.total_points_losers_small = total_points_losers_small
                element.total_points_ratio = getStosunekPTK(total_points_winners, total_points_losers)
            })

            return memberOfDebel.sort((a, b) => b.total_points_ratio - a.total_points_ratio
                || b.total_points_winners - a.total_points_winners
                || a.total_points_losers - b.total_points_losers
                || (b.total_points_winners_small - b.total_points_losers_small) - (a.total_points_winners_small - a.total_points_losers_small)).slice(0, countMembers)
        }
        else {
            return []
        }


    } catch (e) {
        console.log(e)
        return []
    }
}


export function getMembersStatsAll(jsonData, jsonDataMembers) {
    try {



        let tmpM = []
        // tworzymy tablicę statsow
        if (jsonData
            && jsonData.length > 0
            && jsonData[0].matches
            && jsonData[0].matches.length > 0
            && jsonData[0].matches[0].match_details_ex
            && jsonData[0].matches[0].match_details_ex.members
        ) {

            for (const key in jsonData[0].matches[0].match_details_ex.members) {
                if (key === 'matche_cards') {
                    //yellow: 0, yr: 0, red: 0
                    tmpM.push({ key: key, newkey: "matche_cards_yellow", sort: jsonData[0].matches[0].match_details_ex.members[key].sort + 0.1, value: 0 })
                    tmpM.push({ key: key, newkey: "matche_cards_yr", sort: jsonData[0].matches[0].match_details_ex.members[key].sort + 0.2, value: 0 })
                    tmpM.push({ key: key, newkey: "matche_cards_red", sort: jsonData[0].matches[0].match_details_ex.members[key].sort + 0.3, value: 0 })
                } else {
                    if (jsonData[0].matches[0].match_details_ex.members[key].hide_viewers === true) {

                    } else {
                        tmpM.push({ key: key, newkey: key, sort: jsonData[0].matches[0].match_details_ex.members[key].sort, value: 0 })
                    }

                }

            }

        }




        let tmpMT = []
        // lista membersów w drużynie

        jsonDataMembers.forEach(element => {
            if (element.members_list) {
                element.members_list.forEach(element1 => {
                    tmpMT.push({

                        team_id: element.member_id, team_name: element.name, team_img: element.img_file, team_photo_file: element.team_photo_file,
                        member_id: element1.member_id, member_name: element1.member_name, member_img: element1.member_img_file,
                        member_team_id: element1.member_team_id,
                        stats: tmpM.sort((a, b) => a.sort - b.sort),
                    })
                });
            }

        });



        if (tmpMT.length > 0 && jsonData && tmpM.length > 0) {
            // loop po zawodnikach
            let tmpMTtmp = JSON.parse(JSON.stringify(tmpMT));
            tmpMT.forEach((element, j) => {
                // loop po stats
                element.stats.forEach((elementStats, i) => {
                    //count_assist oraz konkretny members



                    jsonData.forEach(jsonDataElement => {
                        jsonDataElement.matches.forEach(jsonDataElementMatches => {
                            if (jsonDataElementMatches.match_details_ex && jsonDataElementMatches.match_details_ex.members) {

                                if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_id === element.team_id) {
                                    try {

                                        if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value) {
                                            tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                        } else if (jsonDataElementMatches.match_details_ex.version === 2) {
                                            tmpMTtmp[j].stats[i].value = getValueDataVer2(element, jsonDataElementMatches.match_details_ex.members[elementStats.key], elementStats.newkey, tmpMTtmp[j].stats[i].value)

                                        }
                                    } catch (e) {
                                        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                            console.log(e)
                                        }
                                    }

                                } else if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_id === element.team_id) {
                                    try {

                                        if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value) {
                                            tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                        } else if (jsonDataElementMatches.match_details_ex.version === 2) {
                                            tmpMTtmp[j].stats[i].value = getValueDataVer2(element, jsonDataElementMatches.match_details_ex.members[elementStats.key], elementStats.newkey, tmpMTtmp[j].stats[i].value)

                                        }
                                    } catch (e) {
                                        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                            console.log(e)
                                        }
                                    }

                                }

                            }
                        })
                    })


                })


            })

            // sortowanie po ostatniej statystyce
            return tmpMTtmp.sort((a, b) => b.stats[a.stats.length - 1].value - a.stats[a.stats.length - 1].value)
        }
        else {
            return []
        }


    } catch (e) {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            console.log(e)
        }
        return []
    }
}


export function getMembersStatsLeague(member_id, jsonData) {
    try {

        if (jsonData.matches) {



            let total_points_winners = 0
            let total_points_losers = 0
            let total_points_winners_small = 0
            let total_points_losers_small = 0
            // tworzymy tablicę wszystkich pojedynków
            jsonData.matches.forEach(element => {
                if (element.matche_ts) {
                    element.matche_ts.forEach(element1 => {
                        if (member_id === element1.team1_member_id && element1.type === 1) {

                            if (element1.score_member1 > element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_losers++
                                total_points_losers_small = total_points_losers_small + element1.score_member2
                                total_points_winners_small = total_points_winners_small + element1.score_member1
                            }

                        } else if (member_id === element1.team2_member_id && element1.type === 1) {

                            if (element1.score_member1 > element1.score_member2) {
                                total_points_losers++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            } else if (element1.score_member1 < element1.score_member2) {
                                total_points_winners++
                                total_points_winners_small = total_points_winners_small + element1.score_member2
                                total_points_losers_small = total_points_losers_small + element1.score_member1
                            }
                        }
                    });
                }

            });

            return { matches: { win: total_points_winners, lose: total_points_losers }, points: { win: total_points_winners_small, lose: total_points_losers_small } }
        } else {
            return null
        }

    } catch (e) {
        console.log(e)
        return null
    }
}


export function getupComingMatches(json, countDay) {
    try {
        let tmp = []
        if (json && json.length > 0) {
            // runda
            json.forEach((row) => {
                // mecz
                if (row.matches && row.matches.length > 0) {
                    row.matches.forEach((rowm) => {
                        // sprwadzamy czy jest czas ustawiony

                        if (rowm.match_date && checkDateBeforeDay(rowm.match_date, countDay) && rowm.member1_score === null) {

                            tmp.push({ ...rowm, league_revenge_round: row.league_revenge_round, round_nr: row.round_nr })
                        }

                    })
                }

            })
        }
        if (tmp.length > 0) {
            return tmp.sort((a, b) => ifNullDate(a.match_date) - ifNullDate(b.match_date))
        }
        return tmp
    } catch (e) {
        console.log(e)
        return []
    }
}

export function getPtkMembersInMatche(teamName, teamAndMembers, tournament_json, isDarkTheme, width) {
    try {
        function getScoreSum(member_id,arr){
            try{
                let ret = 0
                arr.forEach(element => {
                    if(element.match_details_ex && element.match_details_ex.matche_ts){
                        ret = ret + Number(getScore(member_id, element.match_details_ex))
                    }    
                });
                return ret
            }catch(e){
                return 0
            }
        }

        function getScore(member_id, stats) {
            let ret = 0
            let ok = false
            stats.matche_ts.forEach(element => {
                if (element.team1_member_id === member_id && element.type === 1) {
                    ok = true
                    if (element.score_member1 > element.score_member2) {
                        ret++
                    }
                } else if (element.team2_member_id === member_id) {
                    ok = true
                    if (element.score_member1 < element.score_member2) {
                        ret++
                    }
                } else if (element.type === 2 && (element.team1_member1_id === member_id || element.team1_member2_id === member_id)) {
                    ok = true
                    if (element.score_member1 > element.score_member2) {
                        ret = ret + 0.5
                    }
                } else if (element.type === 2 && (element.team2_member1_id === member_id || element.team2_member2_id === member_id)) {
                    ok = true
                    if (element.score_member1 < element.score_member2) {
                        ret = ret + 0.5
                    }
                }
            });

            return ok ? ret : ""
        }


        function getInicjaly(name) {
            try {
                let sp = name.split(" ")
                return sp[0].charAt(0) + sp[1].charAt(0)
            } catch (e) {
                return ""
            }

        }

        let arrMatches = []
        if (tournament_json) {
            tournament_json.forEach(element => {
                element.matches.forEach(element1 => {
                    if (element1.member1_name === teamName && element1.member2_id !== null) {
                        arrMatches.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member2_name, scoreW: element1.member1_score, scoreL: element1.member2_score, score: element1.member1_score !== null ? element1.member1_score + ":" + element1.member2_score : "", status_m: getMi(element1.member1_score, element1.member2_score), match_details_ex: element1.match_details_ex, member_id: element1.member1_id })
                    } else if (element1.member2_name === teamName && element1.member1_id !== null) {
                        arrMatches.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member1_name, scoreW: element1.member2_score, scoreL: element1.member1_score, score: element1.member1_score !== null ? element1.member2_score + ":" + element1.member1_score : "", status_m: getMi(element1.member2_score, element1.member1_score), match_details_ex: element1.match_details_ex, member_id: element1.member2_id })
                    }
                });
            });
        }

        // console.log(arrMatches, teamName, teamAndMembers, tournament_json)
        if (arrMatches.length > 0) {
            let imgTeamPhoto = getImageTeam(teamName, teamAndMembers)
            return <>

                <Grid unstackable>
                    <Grid.Row verticalAlign="middle" >
                        {imgTeamPhoto ? <Grid.Column textAlign="center" width={width < 900 ? 16 : 5}> <MyImageLazy openView={true} maxHeight={400} src={imgTeamPhoto} />  </Grid.Column> : ""}     
                        <Grid.Column width={width < 900 || imgTeamPhoto===null ? 16 : 11}>
                        {getChartsMIT(isDarkTheme, arrMatches, teamName, width,imgTeamPhoto)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <div style={{marginTop:20,marginBottom:30,  overflowX: "auto", width: "100%" }} >
                    <Table style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }}
                        size='small'
                        inverted={isDarkTheme} celled structured striped compact unstackable >
                        <Table.Header >

                            <Table.Row>
                                <Table.HeaderCell width={1} textAlign='center'>NR</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' singleLine >PRZECIWNIK</Table.HeaderCell>
                                {teamAndMembers[teamName].map((line1, j) => line1.total_points_matches > 0 ? <Table.HeaderCell width={1} textAlign='center'>{getInicjaly(line1.member_name)}<span style={{ fontSize: 11 }}>({j + 1})</span></Table.HeaderCell> : "")}
                                <Table.HeaderCell width={1} textAlign='center' singleLine ></Table.HeaderCell>

                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {arrMatches.map((line, j) => <Table.Row>
                                <Table.Cell textAlign='center' width={1} singleLine>{line.league_revenge_round === 0 ? <>{line.round_nr}</> : <>{line.league_revenge_round}R</>}</Table.Cell>
                                <Table.Cell textAlign='center' singleLine>{line.opponent}</Table.Cell>
                                {teamAndMembers[teamName].map((line1, i) => line1.total_points_matches > 0 ? <Table.Cell width={1} textAlign='center'>{getScore(line1.member_id, line.match_details_ex)}</Table.Cell> : "")}
                                <Table.Cell textAlign='right' width={1} singleLine><b>{line.score}</b> {line.status_m}</Table.Cell>
                            </Table.Row>)
                            }
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2} textAlign="right"><b>RAZEM:</b></Table.HeaderCell>
                                {teamAndMembers[teamName].map((line1, i) => line1.total_points_matches > 0 ? <Table.HeaderCell width={1} textAlign='center'><b>{getScoreSum(line1.member_id, arrMatches)} </b></Table.HeaderCell> : "")}
                                <Table.HeaderCell ></Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>

                </div>
            </>
        }

        return ""
    } catch (e) {
        console.log(e)
        return ""
    }
}

function getMi(m1s, m2s) {
    try {
        if (m1s > m2s) {
            return <Icon name={"circle"} color="green" />
        } else if (m1s < m2s) {
            return <Icon name={"circle"} color="red" />
        } else if (m1s === m2s && m1s !== null) {
            return <Icon name={"circle"} color="orange" />
        }

    } catch (e) {
        return ""
    }

}

export function getPtkMembersInMatcheOther(teamName, teamAndMembers, tournament_json, isDarkTheme, width) {
    try {

        function getScoreSum(member_id,arr){
            try{
                let ret = 0
                arr.forEach(element => {
                    if(element.match_details_ex && element.match_details_ex.members && element.match_details_ex.version===2){
                        ret = ret + Number(getScore(member_id, element.match_details_ex))
                    }    
                });
                return ret
            }catch(e){
                return 0
            }
        }

        function getScore(member_id, stats) {
            let ret = 0
            let ok = false
            if (stats.members && stats.version >= 2) {
                if (stats.members.points_scored) {
                    if (stats.members.points_scored.team1_members) {
                        stats.members.points_scored.team1_members.forEach(element => {
                            if (element.member_id === member_id) {
                                ret = element.value
                                ok = true
                            }
                        });
                    }
                    if (stats.members.points_scored.team2_members) {
                        stats.members.points_scored.team2_members.forEach(element => {
                            if (element.member_id === member_id) {
                                ret = element.value
                                ok = true
                            }
                        });
                    }

                } else if (stats.members.points_scored_goals) {
                    if (stats.members.points_scored_goals.team1_members) {
                        stats.members.points_scored_goals.team1_members.forEach(element => {
                            if (element.member_id === member_id) {
                                ret = element.value
                                ok = true
                            }
                        });
                    }
                    if (stats.members.points_scored_goals.team2_members) {
                        stats.members.points_scored_goals.team2_members.forEach(element => {
                            if (element.member_id === member_id) {
                                ret = element.value
                                ok = true
                            }
                        });
                    }

                }

            } else {
                ok = true
                ret = <Icon name="warning sign" color="grey" style={{ opacity: "0.2" }} title="Niezgodna wersja statystyk" />
            }


            return ok ? ret : ""
        }


        function getInicjaly(name) {
            try {
                let sp = name.split(" ")
                return sp[0].charAt(0) + sp[1].charAt(0)
            } catch (e) {
                return ""
            }

        }



        function showUsers(row) {
            try {
                let ret = false
                if (row && row.stats) {
                    row.stats.forEach(element => {
                        if ((element.key === "points_scored" || element.key === "points_scored_goals") && element.value > 0) {
                            ret = true
                            return
                        }
                    });
                }
                return ret
            } catch (e) {
                return true
            }

        }





        let arrMatches = []
        if (tournament_json) {
            tournament_json.forEach(element => {
                element.matches.forEach(element1 => {
                    if (element1.member1_name === teamName && element1.member2_id !== null) {
                        arrMatches.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member2_name, scoreW: element1.member1_score, scoreL: element1.member2_score, score: element1.member1_score !== null ? element1.member1_score + ":" + element1.member2_score : "", status_m: getMi(element1.member1_score, element1.member2_score), match_details_ex: element1.match_details_ex, member_id: element1.member1_id })
                    } else if (element1.member2_name === teamName && element1.member1_id !== null) {
                        arrMatches.push({ round_nr: element.round_nr, league_revenge_round: element.league_revenge_round, opponent: element1.member1_name, scoreW: element1.member2_score, scoreL: element1.member1_score, score: element1.member1_score !== null ? element1.member2_score + ":" + element1.member1_score : "", status_m: getMi(element1.member2_score, element1.member1_score), match_details_ex: element1.match_details_ex, member_id: element1.member2_id })
                    }
                });
            });
        }


        if (arrMatches.length > 0) {
            let imgTeamPhoto = getImageTeam(teamName, teamAndMembers)
            return <>

                <Grid unstackable>
                    <Grid.Row verticalAlign="middle" >
                        {imgTeamPhoto ? <Grid.Column textAlign="center" width={width < 900 ? 16 : 5}> <MyImageLazy openView={true} maxHeight={400} src={imgTeamPhoto} />  </Grid.Column> : ""}     
                        <Grid.Column width={width < 900 || imgTeamPhoto===null ? 16 : 11}>
                        {getChartsMIT(isDarkTheme, arrMatches, teamName, width,imgTeamPhoto)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
               

                <div style={{marginTop:20,marginBottom:30,  overflowX: "auto", width: "100%" }} >
                    <Table style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }}
                        size='small'
                        inverted={isDarkTheme} celled structured striped compact unstackable >
                        <Table.Header >

                            <Table.Row>
                                <Table.HeaderCell width={1} textAlign='center'>NR</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' singleLine >PRZECIWNIK</Table.HeaderCell>
                                {teamAndMembers[teamName].map((line1, j) => showUsers(line1) ? <Table.HeaderCell width={1} textAlign='center'>{getInicjaly(line1.member_name)}<span style={{ fontSize: 11 }}>({j + 1})</span> </Table.HeaderCell> : "")}
                                <Table.HeaderCell width={1} textAlign='center' singleLine ></Table.HeaderCell>

                            </Table.Row>

                        </Table.Header>
                        <Table.Body>
                            {arrMatches.map((line, j) => <Table.Row>

                                <Table.Cell textAlign='center' width={1} singleLine>{line.league_revenge_round === 0 ? <>{line.round_nr}</> : <>{line.league_revenge_round}R</>}</Table.Cell>
                                <Table.Cell textAlign='center' singleLine>{line.opponent}</Table.Cell>
                                {teamAndMembers[teamName].map((line1, i) => showUsers(line1) ? <Table.Cell width={1} textAlign='center'>{getScore(line1.member_id, line.match_details_ex)} </Table.Cell> : "")}
                                <Table.Cell textAlign='right' width={1} singleLine><b>{line.score}</b> {line.status_m}</Table.Cell>
                            </Table.Row>)
                            }
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={2} textAlign="right"><b>RAZEM:</b></Table.HeaderCell>
                                {teamAndMembers[teamName].map((line1, i) => showUsers(line1) ? <Table.HeaderCell width={1} textAlign='center'><b>{getScoreSum(line1.member_id, arrMatches)} </b></Table.HeaderCell> : "")}
                                <Table.HeaderCell ></Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>


                    </Table>

                </div>
            </>
        }

        return ""
    } catch (e) {
        console.log(e)
        return ""
    }
}


export function getImageTeam(teamName, teamAndMembers) {
    try {
        return teamAndMembers[teamName][0].team_photo_file
    } catch (e) {
        console.log(e)
        return null
    }
}


export function getChartsMIT(isDarkTheme, arrMatches, teamName, width,isPhoto) {
    try {

        let labels = []
        let mvalsW = []
        let mvalsL = []
        arrMatches.forEach(line => {

            labels.push(line.league_revenge_round === 0 ? line.round_nr : line.league_revenge_round + "R")
            mvalsW.push(line.scoreW)
            mvalsL.push(line.scoreL)


        });


        let options = {
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            stacked: false,
            plugins: {
                datalabels: {
                    display: true,
                    color: isDarkTheme ? "white" : "black",
                    formatter: Math.round,
                    anchor: "end",
                    offset: -16,
                    align: "start"
                }
            },
            scales: {
                x: {
                    display: true,
                    grid: {
                        color: isDarkTheme ? '#2d2c2c' : '#dbdada'
                    },
                    title: {
                        display: true,
                        text: 'RUNDA'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    grid: {
                        color: isDarkTheme ? '#2d2c2c' : '#dbdada'
                    },
                    title: {
                        display: true,
                        text: 'WYNIK'
                    },
                },

            },
        };

        let dataChart = {
            labels,
            datasets: [
                {
                    label: teamName,
                    data: mvalsW,
                    backgroundColor: isDarkTheme ? 'rgb(37, 124, 60)' : 'rgb(151, 204, 165)',
                },
                {
                    label: 'Przeciwnik',
                    data: mvalsL,
                    backgroundColor: isDarkTheme ? 'rgb(151, 37, 36)' : 'rgb(218, 144, 142)',
                },
            ],
        };



        return <>
            <Bar  data={dataChart} plugins={[ChartDataLabels]} options={options} height={width < 1300 ? 100 : isPhoto ? 90 : 50}  />
        </>
    } catch (e) {
        console.log(e)
        return ""
    }
}