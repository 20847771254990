import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, ButtonOr, Dropdown, Icon, Message, Table } from 'semantic-ui-react';

import AuthContext from '../../context/authContext';
import { ConnectWS } from '../../ConnectWS';
import { useToasts } from 'react-toast-notifications';



function EditLeagueGlobalStatsTT(props) {

    const authContext = useContext(AuthContext);

    const [globalStats, setglobalStats] = useState([])
    const [loading, setloading] = useState(false)
    const { addToast } = useToasts();

    useEffect(() => {
   
        if (props && props.openModalmatch_details_ex && props.openModalmatch_details_ex.row && props.openModalmatch_details_ex.row.match_details_ex && props.openModalmatch_details_ex.row.match_details_ex.matche_ts) {
            setglobalStats(props.openModalmatch_details_ex.row.match_details_ex.matche_ts)
        }
   
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [props])// eslint-disable-line react-hooks/exhaustive-deps

    function closeModal() {
        props.setopenModalmatch_details_ex({ open: false, row: null, onlyView: 1, st: null })
        props.setOpen(false)
    }



    function removeRow(i) {
        let tmp = [...globalStats]
        tmp.splice(i, 1)
        setglobalStats(tmp)
    }

    const reorderArray = (event, originalArray) => {
        const movedItem = originalArray.find((item, index) => index === event.oldIndex);
        const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

        const reorderedItems = [
            ...remainingItems.slice(0, event.newIndex),
            movedItem,
            ...remainingItems.slice(event.newIndex)
        ];

        return reorderedItems;
    }

    function changeOrder(index, direction) {
        setglobalStats(reorderArray({ oldIndex: index, newIndex: index + (direction === "UP" ? (-1) : 1) }, globalStats));
    }

    function addRowT() {
        let tmp = [...globalStats]
        tmp.push({
            "nr": tmp.length + 1,
            "type": 1,
            "details_score": [
                {
                    "nr": 1,
                    "score_member1": null,
                    "score_member2": null
                },
                {
                    "nr": 2,
                    "score_member1": null,
                    "score_member2": null
                },
                {
                    "nr": 3,
                    "score_member1": null,
                    "score_member2": null
                },
                {
                    "nr": 4,
                    "score_member1": null,
                    "score_member2": null
                },
                {
                    "nr": 5,
                    "score_member1": null,
                    "score_member2": null
                }
            ],
            "score_member1": null,
            "score_member2": null,
            "team1_member_id": null,
            "team2_member_id": null
        })
        setglobalStats(tmp)
    }


    function changeTypeR(value, i) {
        let tmp = [...globalStats]
        tmp[i].type = value
        setglobalStats(tmp)
    }


    function saveDb() {
        
        let tmp = [...globalStats]
        tmp.forEach((row,i)=>row.nr=i+1)
        if(tmp.length<1){
            return
        }
        setloading(true)
        ConnectWS("/generator/setSubtournamentMatchJsonTemplate", authContext.token, { subtournament_id:props.openModalmatch_details_ex.subtournament_id,match_info:props.openModalmatch_details_ex.row.match_info, match_details_ex:{matche_ts: globalStats} }, function (response, logout) {
            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                closeModal()
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)

        });
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Message info>W tym oknie możesz zmienić globalnie zbierane statystyki uczestników dla tej ligi</Message>
 
            <Table size='small' celled striped compact unstackable >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1} textAlign='center'>NR POJEDYNKU</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign='center'>TYP POJEDYNKU</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {globalStats && globalStats.length > 0 ?
                        globalStats.map((row, i) => <Table.Row key={i}>
                            <Table.Cell textAlign='center'>
                                {i+1}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <Dropdown
                                    value={row.type}
                                    fluid
                                    selection
                                    onChange={(e, d) => changeTypeR(d.value, i)}
                                    options={[{ key: 1, text: "SINGIEL", value: 1 }, { key: 2, text: "DEBEL", value: 2 }]}
                                />

                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                                {row.type === 2 ? <>
                                    <Icon className='cursorPointer' size='large' name="arrow alternate circle up" title="DO GÓRY" onClick={() => changeOrder(i, "UP")} />
                                    <Icon className='cursorPointer' size='large' name="arrow alternate circle down" title="NA DÓŁ" onClick={() => changeOrder(i, "DOWN")} />
                                </> : ""}
                                <Icon className='cursorPointer' size='large' name="trash" title="KASUJ" onClick={() => removeRow(i)} />
                            </Table.Cell>
                        </Table.Row>) : ""}

                </Table.Body>
            </Table>
            <ButtonGroup size='mini' widths='2'>
                <Button color='blue' fluid onClick={() => addRowT()}>DODAJ NOWY POJEDYNEK</Button>
                <ButtonOr />
                <Button loading={loading} disabled={loading} color='green' fluid onClick={() => saveDb()}>ZAPISZ KONFIGURACJĘ DLA LIGI</Button>
            </ButtonGroup>


        </div>
    )
}

export default EditLeagueGlobalStatsTT;