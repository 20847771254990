

export function getStatsExTournament(data) {
    try {
       
        let dataArrTmp = []
        dataArrTmp.push(data)
        if(data.children && data.children.length>0){
            dataArrTmp.push(...data.children)
        }
        
       
        let allGameBracket = []
        function loopJsonB(jsonTMP) {

            let alljsonTMP = jsonTMP
            Object.entries(jsonTMP).forEach(
                ([key1, value1]) => {
                    if (key1 === "sides") {
                        if ((value1.home.score.score !== null && value1.visitor.score.score !== null)) {
                            allGameBracket.push({
                                member1_id: alljsonTMP.sides.home.team.member_id,
                                member2_id: alljsonTMP.sides.visitor.team.member_id,
                                member1_score: alljsonTMP.sides.home.score.score,
                                member2_score: alljsonTMP.sides.visitor.score.score,
                                match_details_ex: alljsonTMP.match_details_ex
                            })

                        }

                        if (value1.home.seed && value1.home.seed.sourceGame) {
                            loopJsonB(value1.home.seed.sourceGame)
                        }
                        if (value1.visitor.seed && value1.visitor.seed.sourceGame) {
                            loopJsonB(value1.visitor.seed.sourceGame)
                        }
                    }
                }
            );
        }

        let subArr = []
        dataArrTmp.forEach(element => {
            allGameBracket = []
            let jsonTmp = { parentStid: 1, stid: element.subtournament_id, dataEx: [], dataMembers: [] }
            let tmpMStats = []
            let subArrEx = []
            let membersArr = []
            // dodanie do parenta

            if (element.member_type === "TEAM" && element.type !== "LEAGUE") {

                if (element.type.includes("GROUP")) {
                    // tworzymy tablicę statsow
                    for (const key in element.groups[0].matches[0].match_details_ex.members) {
                        if(key==='matche_cards'){
                            //yellow: 0, yr: 0, red: 0
                            tmpMStats.push({ key: key,newkey: "matche_cards_yellow", sort: element.groups[0].matches[0].match_details_ex.members[key].sort+0.1, value: 0 })
                            tmpMStats.push({ key: key,newkey: "matche_cards_yr", sort: element.groups[0].matches[0].match_details_ex.members[key].sort+0.2, value: 0 })
                            tmpMStats.push({ key: key,newkey: "matche_cards_red", sort: element.groups[0].matches[0].match_details_ex.members[key].sort+0.3, value: 0 })
                        }else{
                            if(element.groups[0].matches[0].match_details_ex.members[key].hide_viewers===true){
                            }else{
                                tmpMStats.push({ key: key,newkey: key, sort: element.groups[0].matches[0].match_details_ex.members[key].sort, value: 0 })
                            }         
                        }
                        
                    }
                } else if (element.type.includes("CUP")) {
                    // tworzymy tablicę statsow
                    for (const key in element.tournament_json[0].match_details_ex.members) {
                        if(key==='matche_cards'){
                            //yellow: 0, yr: 0, red: 0
                            tmpMStats.push({ key: key,newkey: "matche_cards_yellow", sort: element.tournament_json[0].match_details_ex.members[key].sort, value: 0 })
                            tmpMStats.push({ key: key,newkey: "matche_cards_yr", sort: element.tournament_json[0].match_details_ex.members[key].sort, value: 0 })
                            tmpMStats.push({ key: key,newkey: "matche_cards_red", sort: element.tournament_json[0].match_details_ex.members[key].sort, value: 0 })
                        }else{
                            if(element.tournament_json[0].match_details_ex.members[key].hide_viewers===true){
                            }else{
                                tmpMStats.push({ key: key,newkey: key, sort: element.tournament_json[0].match_details_ex.members[key].sort, value: 0 })
                            } 
                           
                        }
                       
                    }
                }

                // szukanie uczestnikó
                // dodajemy membersów z parenta

                element.members.forEach(melement1 => {
                    if(melement1.members_list && melement1.members_list.length>0){
                        melement1.members_list.forEach(melement2 => {

                            membersArr.push({
                                team_id: melement1.member_id,
                                team_name: melement1.name,
                                team_img: melement1.img_file,
                                member_id: melement2.member_id,
                                member_name: melement2.member_name,
                                member_img_file: melement2.member_img_file,
                                member_team_id: melement2.member_team_id,
                                stats: tmpMStats.sort((a, b) => a.sort - b.sort)
                            })
                        }) 
                    }
                   
                })


                jsonTmp.dataMembers = membersArr

                //szukanie ex-ow
                if (element.type.includes("GROUP")) {
                    element.groups.forEach(element1 => {
                        element1.matches.forEach(element2 => {
                            if (element2.match_details_ex) {
                                subArrEx.push({
                                    member1_id: element2.member1_id,
                                    member2_id: element2.member2_id,
                                    member1_score: element2.member1_score,
                                    member2_score: element2.member2_score,
                                    match_details_ex: element2.match_details_ex
                                })
                            }
                        })
                    })
                    jsonTmp.dataEx = subArrEx
                } else if (element.type.includes("CUP")) {

                    element.tournament_json.forEach(element1 => {
                        loopJsonB(element1)
                    })

                    jsonTmp.dataEx = [...allGameBracket]
                }
                subArr.push(jsonTmp)
            }
        });

       
        // scalenie parentsow
        let preA = []

        if (subArr.length > 0) {
            subArr.forEach((element1,i) => {
                if (i === 0) {
                    preA.push(element1)
                } else {
                    var found = preA.filter(function (item) { return item.parentStid === element1.parentStid; });
                    if (found && found.length === 1) {
                        let tmpdataEx = []
                        let tmpdataMembers = []
                        tmpdataEx = [...found[0].dataEx]
                        tmpdataMembers = [...found[0].dataMembers]

                        tmpdataEx = tmpdataEx.concat(element1.dataEx)
                        tmpdataMembers = tmpdataMembers.concat(element1.dataMembers)
                        found[0].dataEx = tmpdataEx
                        found[0].dataMembers = uniqBy(tmpdataMembers, JSON.stringify)

                    }
                }
            })
        }
       
        // zaczynamy zabawe
        let retArr = []
      
        if (preA.length > 0) {
          
            preA.forEach(element1 => {
                if (element1.dataMembers.length > 0 && element1.dataEx.length > 0) {
                    // loop po zawodnikach
                    let tmpMTtmp = JSON.parse(JSON.stringify(element1.dataMembers));
                    element1.dataMembers.forEach((element, j) => {
                        // loop po stats 
                        element.stats.forEach((elementStats, i) => {
                            //count_assist oraz konkretny members
                            element1.dataEx.forEach(jsonDataElementMatches => {


                                if (jsonDataElementMatches.match_details_ex && jsonDataElementMatches.match_details_ex.members) {

                                    if (jsonDataElementMatches.match_details_ex.members[elementStats.key] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_id === element.team_id) {
                                        try {
                                          
                                            if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value) {
                                                tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team1_members[element.member_team_id].value, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                            }else if(jsonDataElementMatches.match_details_ex.version===2){
                                                tmpMTtmp[j].stats[i].value = getValueDataVer2(element,jsonDataElementMatches.match_details_ex.members[elementStats.key], elementStats.newkey, tmpMTtmp[j].stats[i].value) 
                                            
                                            }
                                        } catch (e) {
                                            if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                                console.log(e)
                                            }
                                        }

                                    } else if (jsonDataElementMatches.match_details_ex.members[elementStats.key] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_id === element.team_id) {
                                        try {
                                            if (jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id] && jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value) {
                                                tmpMTtmp[j].stats[i].value = getValueData(jsonDataElementMatches.match_details_ex.members[elementStats.key].team2_members[element.member_team_id].value, elementStats.newkey, tmpMTtmp[j].stats[i].value)
                                            }else if(jsonDataElementMatches.match_details_ex.version===2){
                                                tmpMTtmp[j].stats[i].value = getValueDataVer2(element,jsonDataElementMatches.match_details_ex.members[elementStats.key], elementStats.newkey, tmpMTtmp[j].stats[i].value) 
                                            
                                            }
                                        } catch (e) {
                                            if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
                                                console.log(e)
                                            }
                                        }

                                    }

                                }

                            })

                        })

                    })
                  
                    retArr.push({ stid: element1.stid, dataMembers: tmpMTtmp.sort((a, b) => b.stats[a.stats.length - 1].value - a.stats[a.stats.length - 1].value) })
                }
            })
        }


       
        return retArr
    } catch (e) {
        console.log(e)
        return []
    }
}

export function uniqBy(a, key) {
    return [
        ...new Map(
            a.map(x => [key(x), x])
        ).values()
    ]
}

export function getValueData(value, jsontype, prevValue) {
 
    if (value === null ) {
        return 0 + prevValue
    } else if (jsontype === 'in_match' || jsontype === 'mvp') {
        if (value) {
            // true
            return 1 + prevValue
        } else {
            return 0 + prevValue
        }
    } else if (jsontype === "matche_cards_yellow") {
        if(value==="yellow"){
            return 1 + prevValue 
        }else {
            return 0 + prevValue
        }
    } else if (jsontype === "matche_cards_yr") {
        if(value==="yellow-red"){
            return 1 + prevValue 
        }else {
            return 0 + prevValue
        }
    } else if (jsontype === "matche_cards_red") {
        if(value==="red"){
            return 1 + prevValue 
        }else {
            return 0 + prevValue
        }
    } else {
        return Number(value) + prevValue
    }

}

export function getValueDataVer2(members,rowMatch, jsontype, prevValue) {
   
    let value = null
    let valuetmp = null
    if(rowMatch.team1_id===members.team_id){
        valuetmp = rowMatch.team1_members.find(item => {return item.member_id === members.member_id})
    }else if(rowMatch.team2_id===members.team_id){
        valuetmp = rowMatch.team2_members.find(item => {return item.member_id === members.member_id})
    }

    if(valuetmp && valuetmp.member_id=== members.member_id && valuetmp.value){
        value = valuetmp.value
    }


    if (value === null ) {
        return 0 + prevValue
    } else if (jsontype === 'in_match' || jsontype === 'mvp') {
        if (value) {
            // true
            return 1 + prevValue
        } else {
            return 0 + prevValue
        }
    } else if (jsontype === "matche_cards_yellow") {
        if(value==="yellow"){
            return 1 + prevValue 
        }else {
            return 0 + prevValue
        }
    } else if (jsontype === "matche_cards_yr") {
        if(value==="yellow-red"){
            return 1 + prevValue 
        }else {
            return 0 + prevValue
        }
    } else if (jsontype === "matche_cards_red") {
        if(value==="red"){
            return 1 + prevValue 
        }else {
            return 0 + prevValue
        }
    } else {
        return Number(value) + prevValue
    }

}