import React, { useState, useEffect, useContext, useRef } from 'react';
import { Icon, Header, Divider, Segment, Form, Button, Modal, Grid, Image, Table, Container, Message, Checkbox, ButtonGroup, ButtonOr } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { AG_GRID_LOCALE_PL } from '../../../../LanguageAGG';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { ConnectWS } from '../../../../ConnectWS';
import { checkDateOnlyStartBeforeNow, checkiscompanypay, getLimitUsersPackage, getlinkplayerprofile } from '../../../../myfunctions';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import AddEmailForMember from '../../../../tools/AddEmailForMember';
import AGGridActivity from '../../../../AGGridActivity';
import ShowImgAvatar from '../../../../tools/ShowImgAvatar';
import EditImgAvatar from '../../../../tools/EditImgAvatar';
import EditEmailForMember from '../../../../tools/EditEmailForMember';
import JudgesList from './Judges/JudgesList';



function Members(props) {
  const { addToast } = useToasts();
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const authContext = useContext(AuthContext);
  const gridRef = useRef();
  const gridRef1 = useRef();
  const [gridApi, setGridApi] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);// eslint-disable-line no-unused-vars
  const [rowData, setRowData] = useState();
  const [gridApi2, setGridApi2] = useState(null);// eslint-disable-line no-unused-vars
  const [gridColumnApi2, setGridColumnApi2] = useState(null);// eslint-disable-line no-unused-vars
  const [rowData2, setRowData2] = useState();
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [Tournament, setTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [SubTournament, setSubTournament] = useState([]);// eslint-disable-line no-unused-vars


  const [disabledButtonSave, setdisabledButtonSave] = useState(true)

  const [chboxall, setchboxall] = useState(false)
  const [open, setOpen] = useState({ open: false, data: null, dataNew: null })
  const [openAddEmail, setopenAddEmail] = useState({ open: false, data: null })
  const [openEditEmail, setopenEditEmail] = useState({ open: false, data: null })
  const [editPicUser, seteditPicUser] = useState({ img_file: null, member_id: null })

  const [userTypeShow, setuserTypeShow] = useState("USER")

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)

  };

  const onGridReady2 = (params) => {
    setGridApi2(params.api)
    setGridColumnApi2(params.columnApi)

  };

  const [form, setForm] = useState({// eslint-disable-line no-unused-vars
    tournamentid: 0,
    subtournament_id: 0,
  });

  function getTournaments(showALLData) {

    ConnectWS("/generator/getTournaments", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          if ((checkDateOnlyStartBeforeNow(data.start_date) || (showALLData === true)) && data.tournament_free > 0) {
            dictemat.push({ key: i, text: data.name + " - " + data.start_date, value: JSON.stringify({ tournament_id: data.tournament_id, limitUsers: getLimitUsersPackage(data.tournament_free) }) })
          }
        })
        //setRowDataTournament(response.data)
        setTournament(dictemat);
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function getAllMembers(tournamentid, subtournament_id) {
    ConnectWS("/generator/getAllMembers", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          if (userTypeShow === data.member_type) {
            dictemat.push({ ...data, formtournamentid: tournamentid, tournamentid: JSON.parse(tournamentid).tournament_id, limitUsers: JSON.parse(tournamentid).limitUsers, subtournament_id: subtournament_id })
          }

        })
        setRowData(dictemat);

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }


 
    });
  }


  function changeMemberMainPageFinderVisibilityByOrganizer(data, value) {
    console.log(data, value)
    ConnectWS("/generator/changeMemberMainPageFinderVisibilityByOrganizer", authContext.token, { member_id: data.member_id, hide_main_page_finder: value }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        getAllMembers(0, 0)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }



  function ChangeTournamentBox(data) {

    ConnectWS("/generator/getSubtournaments", authContext.token, { tournament_id: JSON.parse(data.value).tournament_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name, value: data.subtournament_id })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })
        setSubTournament(dictemat);

        if (dictemat.length === 1) {

          setForm({ ...form, tournamentid: data.value, subtournament_id: dictemat[0].value })
          ChangeSubTournamentBox(data.value, dictemat[0].value)
        } else {

          setForm({ ...form, tournamentid: data.value })
        }

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function ChangeSubTournamentBox(tournamentid, value) {
    getAllMembers(tournamentid, value);
  }




  useEffect(() => {
    seteditPicUser({ img_file: null, member_id: null })
    setForm({ tournamentid: 0, subtournament_id: 0, })
    getTournaments(false)
    getAllMembers(0, 0)
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [authContext.changeRefreshView])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getAllMembers(0, 0)
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [userTypeShow])// eslint-disable-line react-hooks/exhaustive-deps




  function addMemberToTournament(props) {

    if (props.data.tournamentid !== 0 && props.data.subtournament_id !== 0) {
      /*
        confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz dodać uczestnika do wybranego turnieju?',
        buttons: [
          {
            label: 'Tak',
            onClick: () => addMemberToTournamentA(props)
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });
      */
      addMemberToTournamentA(props)
    } else {
      addToast("Proszę wybrać turniej i kategorię", { appearance: 'warning' });
    }

  };

  function addMemberToTournamentA(props) {
    if (props.data.tournamentid !== 0 && typeof props.data.tournamentid !== "undefined" && props.data.subtournament_id !== 0) {
      ConnectWS("/generator/addMemberToTournament", authContext.token, { subtournament_id: props.data.subtournament_id, member_id: props.data.member_id, name: props.data.member_name, limit_users: props.data.limitUsers }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          getAllMembers(props.data.formtournamentid, props.data.subtournament_id)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }
      });
    } else {
      addToast("Proszę wybrać turniej i kategorię", { appearance: 'warning' });
    }

  }

  function removeUsersTournament(props) {
    if (props.data.tournamentid !== 0 && props.data.subtournament_id !== 0) {
      /*
      confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz usunać uczestnika z wybranego turnieju?',
        buttons: [
          {
            label: 'Tak',
            onClick: () => removeUsersTournamentA(props)
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });
      */
      removeUsersTournamentA(props)
    } else {
      addToast("Proszę wybrać turniej i kategorię", { appearance: 'warning' });
    }

  };

  function removeUsersTournamentA(props) {
    ConnectWS("/generator/removeMemberFromTournament", authContext.token, { subtournament_id: props.data.subtournament_id, tournament_member_id: props.data.tournament_member_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getAllMembers(props.data.formtournamentid, props.data.subtournament_id)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }

  const rowAdd = props => {
    return props.data.subtournament_ok === 1 ? <div style={{ cursor: "pointer" }} onClick={() => removeUsersTournament(props)} ><Icon title="Usuń z turnieju" color="red" style={{ fontSize: 16 }} name='minus' /></div> : <div style={{ cursor: "pointer" }} onClick={() => addMemberToTournament(props)} ><Icon title="Dodaj do turnieju" color="orange" style={{ fontSize: 16 }} name='add' /></div>
  };

  const scalPlayers = props => {
    return <Button icon size='mini' onClick={() => openModalData(props.data)} fluid style={{ height: 20, padding: 0, paddingLeft: 10, paddingRight: 10, paddingTop: 3, marginTop: 2 }}> SCAL</Button>
  };

  const addEmail = props => {
    return !props.data.member_email ? <div style={{ cursor: "pointer" }} title={"Dodaj adres email"} onClick={() => setopenAddEmail({ open: true, data: props.data })} > <Icon.Group style={{ fontSize: 16 }} color="blue">
      <Icon name='mail' color="blue" />
      <Icon corner name='add' color="blue" />
    </Icon.Group></div> : <Icon title={"Adres email dodany"} color="orange" style={{ fontSize: 15, cursor: "pointer" }} onClick={() => setopenEditEmail({ open: true, data: props.data })} name='edit' />

  };

  const changehide_main_page_finder = props => {
    return props.data.member_email || props.data.member_id === null ? "" : props.data.hide_main_page_finder ? <Icon className='cursorPointer' title={"Profil prywatny - ukryty w wyszukiwaniach na stronie głównej  oraz w osiągnięciach"} onClick={() => changeMemberMainPageFinderVisibilityByOrganizer(props.data, false)} color="grey" style={{ fontSize: 16 }} name='lock' /> : <Icon className='cursorPointer' onClick={() => changeMemberMainPageFinderVisibilityByOrganizer(props.data, true)} title={"Profil publiczny - dostępny w wyszukiwaniach na stronie głównej"} color="blue" style={{ fontSize: 16 }} name='globe' />

  };

  const showAvatar = props => {

    return <div style={{ cursor: "pointer", position: "relative" }} ><div className='imgPlayer2'><ShowImgAvatar urlpic={props.data.member_img_file} mtype={"0"} msize={"mini"} /></div>
      {props.data.member_id ? <Icon style={{ top: 0, right: 0, position: "absolute" }} name="edit" color='orange' onClick={() => seteditPicUser({ img_file: props.data.member_img_file ? props.data.member_img_file : process.env.REACT_APP_PUBLIC_URL + '/img/nouser.png', member_id: props.data.member_id })} /> : ""}
    </div>

  };

  const showMemberStats = props => {

    return <div >
      {getlinkplayerprofile(props.data.member_id, props.data.member_name, 1, 13)}
    </div>

  };


  function getAllMembersSearch(data1) {

    ConnectWS("/generator/getAllMembers", authContext.token, { subtournament_id: 0 }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          if (data1.member_name.replace(/ /g, '') === data.member_name.replace(/ /g, '') && data.member_email !== null && data.member_email !== data1.member_email) {
            dictemat.push({ ...data })
          }
        })
        setRowData2(dictemat);
        setOpen({ open: true, data: data1, dataNew: null })
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }

  function openModalData(data) {
    setdisabledButtonSave(true)
    getAllMembersSearch(data)
  }

  function closeModalData() {
    setOpen({ open: false, data: null, dataNew: null })
  }


  const onSelectionChanged = () => {
    var selectedRows = gridApi2.getSelectedRows();
    if (selectedRows.length === 1) {
      setdisabledButtonSave(false)
      setOpen({ ...open, dataNew: selectedRows[0] })
    } else {
      setdisabledButtonSave(true)
    }
  }



  function changeUser() {
    ConnectWS("/generator/replacingParticipants", authContext.token, { oldUser: open.data, newUser: open.dataNew }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        closeModalData()
        getAllMembers(form.tournamentid, form.subtournament_id)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }

  function changechboxx(val) {
    setchboxall(val)
    if (val) {
      getTournaments(true)
    } else {
      getTournaments(false)
    }

  }



  return (
    <><Segment>


      <Header as='h5' textAlign='center'>
        <Icon name='users' />
        <Header.Content>Uczestnicy</Header.Content>
      </Header>
      <Divider />
      {editPicUser.member_id ? <EditImgAvatar memberData={editPicUser} playerid={editPicUser.member_id} forceOpen={true} /> : ""}
      <Form style={{ marginTop: 10 }} size='tiny'>
        <Form.Field>
          <Checkbox label='Pobierz wszystkie rozgrywki' checked={chboxall} onChange={(e, d) => changechboxx(d.checked)} />
        </Form.Field>

        <Form.Group inline widths='equal'>

          <Form.Select
            fluid
            search
            label={chboxall ? "Wszystkie rozgrywki" : "Rozgrywki tylko nowe"}
            options={Tournament}
            value={form.tournament_id}
            onChange={(e, data) => ChangeTournamentBox(data)}
            placeholder='Proszę wybrać...'
          />


          <Form.Select
            fluid
            search
            label="Kategoria"
            placeholder='Proszę wybrać...'
            options={SubTournament}
            value={form.subtournament_id}
            onChange={(e, data) => {
              setForm({ ...form, subtournament_id: data.value })
              ChangeSubTournamentBox(form.tournamentid, data.value)
            }}

          />

        </Form.Group>
        <Message size='mini' info style={{ marginBottom: 10 }}>
          Lista zawiera jedynie turnieje oraz ligi utworzone w pakietach wyższych niż brązowy.
        </Message>

      </Form>
      <Button fluid size='tiny' onClick={() => getAllMembers(form.tournamentid, form.subtournament_id)}>POBIERZ DANE</Button>
    </Segment>


      <ButtonGroup fluid>
        <Button color={userTypeShow === "USER" ? 'blue' : "grey"} onClick={() => setuserTypeShow("USER")}>ZAWODNICY</Button>
        <ButtonOr text='lub' />
        <Button color={userTypeShow === "TEAM" ? 'blue' : "grey"} onClick={() => setuserTypeShow("TEAM")}>DRUŻYNY</Button>
        {checkiscompanypay() ? <>
        <ButtonOr text='lub' />
        <Button color={userTypeShow === "JUDGES" ? 'blue' : "grey"} onClick={() => setuserTypeShow("JUDGES")}>SĘDZIOWIE</Button>
        </> : ""}
      </ButtonGroup>


      <Segment color='blue'>

            {userTypeShow === "JUDGES" ? <JudgesList addJudges={true} selectionType={0}/> : <>
        <AGGridActivity quickSearchHidden={false} gridApi={gridApi} gridColumnApi={gridColumnApi} gridApiRef={gridRef1} />
        <div ref={gridRef} className="ag-theme-balham" style={{ position: "relative", marginTop: 10, height: height < 900 ? height - 80 : height - 320, width: "100%" }}>


          <AgGridReact
            ref={gridRef1}
            //pagination={true}
            animateRows={true}
            immutableData={true}
            defaultColDef={{
              editable: false,
              sortable: true,
              minWidth: 100,
              filter: false,
              resizable: true,
              floatingFilter: false,
              flex: 1,
            }}
            rowClassRules={{
              'row-ok': function (params) {
                if (params.data) {
                  return params.data.subtournament_ok === 1
                }
                return false
              },
            }}
            frameworkComponents={{
              rowAdd: rowAdd,
              scalPlayers: scalPlayers,
              addEmail: addEmail,
              showAvatar: showAvatar,
              showMemberStats: showMemberStats,
              changehide_main_page_finder: changehide_main_page_finder
            }}
            getRowNodeId={function (data) {
              return data["id"];
            }}
            localeText={AG_GRID_LOCALE_PL}
            onGridReady={onGridReady}
            rowData={rowData}
          >
            <AgGridColumn cellRenderer='rowAdd' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='scalPlayers' minWidth={40}
              maxWidth={100}
              width={100}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} headerName="Scal" />

            <AgGridColumn cellRenderer='showAvatar' minWidth={70}
              maxWidth={70}
              width={70}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='showMemberStats' headerName="Uczestnik"
              minWidth={150}
              width={150}
              filter='agTextColumnFilter'

            />
            <AgGridColumn field="member_name" hide={true} headerName="" filter="agMultiColumnFilter" />


            <AgGridColumn cellRenderer='addEmail' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn cellRenderer='changehide_main_page_finder' minWidth={40}
              maxWidth={40}
              width={40}
              flex={0}
              resizable={false}
              sortable={false}
              editable={false}
              filter={false} />

            <AgGridColumn field="member_email" headerName="Email" filter="agMultiColumnFilter" />
            <AgGridColumn field="lictl" headerName="Numer TG" filter="agMultiColumnFilter" />
            <AgGridColumn field="tournament_last" headerName="Data ostatniego udziału w rozgrywkach" filter="agMultiColumnFilter" />
            <AgGridColumn field="tournament_count" headerName="Ilość rozgrywek"
              filter="agMultiColumnFilter" />
            <AgGridColumn minWidth={120} width={120} field="member_type_m" headerName="Rodzaj uczestnika" filter="agMultiColumnFilter" />



          </AgGridReact>
        </div>

        </>}

      </Segment>
      <Modal
        centered={width < 900 ? false : true}
        onClose={() => closeModalData()}
        open={open.open}
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='retweet' color="red" />
                  <Header.Content> SCAL UCZESTNIKA</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' alt="logo" size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content >
          <Modal.Description >
            <Container textAlign="center">
              {open && open.open ? <>
                <Message size='mini' info>
                  <p>
                    W tym oknie możesz scalić uczestników z taką samą nazwą.<br />
                    Uczestnicy posiadający wygenerowany NRTG mogą być wybrani do podmiany.
                  </p>
                </Message>
                Wybrany Uczestnik:
                <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                  <Table size='small' celled striped compact unstackable >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell >UCZESTNIK</Table.HeaderCell>
                        <Table.HeaderCell >EMAIL</Table.HeaderCell>
                        <Table.HeaderCell >NRTG</Table.HeaderCell>
                        <Table.HeaderCell >OSTATNIE ROZGRYWKI</Table.HeaderCell>
                        <Table.HeaderCell >ILOŚĆ ROZGRYWEK</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell >{open.data.member_name}</Table.Cell>
                        <Table.Cell >{open.data.member_email}</Table.Cell>
                        <Table.Cell >{open.data.lictl}</Table.Cell>
                        <Table.Cell >{open.data.tournament_last}</Table.Cell>
                        <Table.Cell >{open.data.tournament_count}</Table.Cell>

                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <Header as='h3' icon>
                  <Icon name='retweet' />
                  PODMIENIAM POWYŻSZEGO UCZESTNIKA
                  <Header.Subheader>
                    Powyższy uczestnik zostanie skasowany i podmieniony poniżej wybranym
                  </Header.Subheader>
                </Header>


                <div className="ag-theme-balham" style={{ textAlign: "left", position: "relative", marginTop: 10, height: 200, width: "100%" }}>


                  <AgGridReact
                    //pagination={true}
                    rowSelection={'single'}
                    onSelectionChanged={onSelectionChanged}
                    animateRows={true}
                    defaultColDef={{
                      editable: false,
                      sortable: true,
                      minWidth: 100,
                      filter: false,
                      resizable: true,
                      floatingFilter: false,
                      flex: 1,
                    }}

                    localeText={AG_GRID_LOCALE_PL}
                    onGridReady={onGridReady2}
                    rowData={rowData2}
                  >

                    <AgGridColumn minWidth={100} checkboxSelection={true}
                      width={170} field="member_name" headerName="Uczestnik" filter="agMultiColumnFilter" />
                    <AgGridColumn field="member_email" headerName="Email" filter="agMultiColumnFilter" />
                    <AgGridColumn field="lictl" headerName="Numer TG" filter="agMultiColumnFilter" />
                    <AgGridColumn field="tournament_last" headerName="Data ostatniej rozgrywki" filter="agMultiColumnFilter" />
                    <AgGridColumn field="tournament_count" headerName="Ilość rozgrywek"
                      filter="agMultiColumnFilter" />

                  </AgGridReact>
                </div>
                <Button color='red' disabled={disabledButtonSave} size='mini' style={{ marginTop: 10 }} fluid onClick={() => changeUser()}>
                  PODMIEN UCZESTNIKÓW
                </Button>
              </> : ""}
            </Container>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => closeModalData()}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
      {openAddEmail.open ?
        <AddEmailForMember OpenModalAEFM={openAddEmail.open} OpenModalAEFMDATA={openAddEmail.data} setdataMailMembers={setopenAddEmail} dateForce={false} />
        : ""}
      {openEditEmail.open ?
        <EditEmailForMember OpenModalAEFM={openEditEmail.open} OpenModalAEFMDATA={openEditEmail.data} setdataMailMembers={setopenEditEmail} dateForce={false} />
        : ""}

    </>
  )
}

export default Members;