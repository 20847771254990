import React, { useEffect, useContext, useState } from 'react';
import { Button, Form, FormGroup, Grid, Header, Icon, Image, Input, Message, Modal, Portal, Segment } from 'semantic-ui-react';
import AuthContext from '../context/authContext';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { noSubIsActive } from '../myfunctions';
import { ConnectWS } from '../ConnectWS';
import { useToasts } from 'react-toast-notifications';

import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import useCheckUserIsDemo from '../hooks/useCheckUserIsDemo';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import AddMediaUserFile from './AddMediaUserFile';
import { useHistory } from 'react-router-dom';
import JudgesList from '../layout/ui/Dashboard/MyAccount/Judges/JudgesList';

function EditLeagueMatchInfo(props) {
  let history = useHistory();
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [open, setOpen] = useState(false)
  const [match_info, setmatch_info] = useState(null)
  const [loading, setloading] = useState(false)
  const isDemo = useCheckUserIsDemo()
  const { t } = useTranslation();
  const [isAS, setisAS] = useState(false)

  const [openPortalModuleJudges, setopenPortalModuleJudges] = useState(null)
  const [selectedJudgesList, setselectedJudgesList] = useState(null)

  const [fileMediaSelect, setfileMediaSelect] = useState(null)

  function checkSubIsActiv() {
    try {
      if (isDemo) {
        setisAS(true)
      } else if (authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended >= 0 && authContext.user.subscription_info.subscription_active) {
        setisAS(true)
      } else {
        setisAS(false)
      }
    } catch (e) {
      setisAS(false)
    }
  }


  useEffect(() => {

    if (selectedJudgesList && openPortalModuleJudges) {
      if (selectedJudgesList.length > 0) {
        let jsonTmp = { ...match_info }
        jsonTmp[openPortalModuleJudges].person_id = selectedJudgesList[0].person_id
        jsonTmp[openPortalModuleJudges].confirmed = false
        jsonTmp[openPortalModuleJudges].value = selectedJudgesList[0].person_name
        setmatch_info({ ...jsonTmp })
      } else {
        let jsonTmp = { ...match_info }
        jsonTmp[openPortalModuleJudges].person_id = null
        jsonTmp[openPortalModuleJudges].confirmed = null
        jsonTmp[openPortalModuleJudges].amount = null
        jsonTmp[openPortalModuleJudges].value = null
        setmatch_info({ ...jsonTmp })
      }

    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [selectedJudgesList])// eslint-disable-line react-hooks/exhaustive-deps




  useEffect(() => {

    if (props.openModalmatch_info.open) {
      checkSubIsActiv()
      setOpen(props.openModalmatch_info.open)
      if (props.openModalmatch_info.row.match_info) {
        try {
          setmatch_info(JSON.parse(JSON.stringify(props.openModalmatch_info.row.match_info)))
        } catch (e) {

        }

      }

    } else {
      setOpen(props.openModalmatch_info.open)

    }
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [props.openModalmatch_info])// eslint-disable-line react-hooks/exhaustive-deps


  function exitModal() {

    props.setopenModalmatch_info({ open: false, row: null })
    setOpen(false)
  }

  function updateJsonData(value, key) {
    let jsonTmp = { ...match_info }
    jsonTmp[key].value = value
    setmatch_info({ ...jsonTmp })
  }

  function updateJsonDataJson(value, key, typeJson) {
    let jsonTmp = { ...match_info }
    jsonTmp[key][typeJson] = value
    setmatch_info({ ...jsonTmp })
  }

  function checkIsSubDP() {
    try {

      if (authContext.user && authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  function getmatch_info(match_info) {
    try {
      const rows = [];
      for (const [key] of Object.entries(match_info).sort((a, b) => a[1].sort - b[1].sort)) {

        if (key === 'match_report') {
          rows.push(
            <FormGroup widths='equal'>
              <Form.Field
                control={Input}
                type={match_info[key].type === "inputnumber" ? "number" : match_info[key].type === "inputurl" ? "url" : "text"}
                value={match_info[key].value}
                label={t("stats." + match_info[key].name) + " (link)"}
                onChange={(e, d) => updateJsonData(d.value, key)}
                placeholder={t("stats." + match_info[key].name)}
              />
              <AddMediaUserFile disabledButton={!checkIsSubDP()} fileMediaSelect={fileMediaSelect} setfileMediaSelect={setfileMediaSelect} />
            </FormGroup>
          )
        } else if (key === 'google_photo') {
          rows.push(
            <div style={{ marginBottom: 10 }}>
              <Form.Field
                control={Input}
                disabled={!checkIsSubDP()}
                type={match_info[key].type === "inputnumber" ? "number" : match_info[key].type === "inputurl" ? "url" : "text"}
                value={match_info[key].value}
                label={!checkIsSubDP() ? <div ><Icon name="lock" />Album Google {t("stats." + match_info[key].name) + " (link)"}</div> : t("stats." + match_info[key].name) + " (link)"}
                onChange={(e, d) => updateJsonData(d.value, key)}
                placeholder={t("stats." + match_info[key].name)}
              />
              {checkIsSubDP() ? "Link do publicznego albumu GOOGLE ZDJĘCIA: https://photos.app.goo.gl/idgalerii" : "*Brak aktywnej subskrypcji"}
            </div>
          )
        } else if (match_info[key].type === "moduleJudges") {
          rows.push(
            <div style={{ marginBottom: 10 }}>
              <FormGroup widths='equal'>
                <Form.Field
                  control={Input}
                  type={"text"}
                  readOnly
                  value={match_info[key].value ? match_info[key].value : ""}
                  label={t("stats." + match_info[key].name)}
                  placeholder={t("stats." + match_info[key].name)}
                />
                <Form.Field
                  control={Input}
                  type={"number"}
                  value={match_info[key].amount ? match_info[key].amount : ""}
                  label={"Kwota"}
                  onChange={(e, d) => updateJsonDataJson(Number(d.value), key, "amount")}

                  placeholder={"Kwota"}
                />
                <Button type='button' color='grey' size='mini' onClick={() => setopenPortalModuleJudges(key)}>WYBIERZ SĘDZIE</Button>

              </FormGroup>
            </div>
          )
        } else {
          rows.push(
            <Form.Field
              control={Input}
              type={match_info[key].type === "inputnumber" ? "number" : match_info[key].type === "inputurl" ? "url" : "text"}
              value={match_info[key].value}
              label={match_info[key].type === "inputurl" || match_info[key].type === "inputvideourl" || match_info[key].type === "inputgooglephotourl" ? t("stats." + match_info[key].name) + " (link)" : t("stats." + match_info[key].name)}
              onChange={(e, d) => updateJsonData(d.value, key)}
              placeholder={t("stats." + match_info[key].name)}
            />


          )
        }
      }
      return rows;
    } catch (e) {
      console.log(e)
    }
  }

  function saveDataMI(e) {
    e.preventDefault();
    let match_id = props.openModalmatch_info.row.subtournament_league_match_id
    setloading(true)
    ConnectWS("/generator/setMatchInfo", authContext.token, { match_id: match_id, match_info: { ...match_info }, subtournament_id: props.openModalmatch_info.subtournament_id }, function (response, logout) {
      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
        setOpen(false)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloading(false)

    });

  }


  function restoreStats() {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz zresetować informacje dla tego pojedynku?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => restoreStatsacc()
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function restoreStatsacc() {

      let match_id = props.openModalmatch_info.row.subtournament_league_match_id
      setloading(true)
      ConnectWS("/generator/setMatchInfo", authContext.token, { match_id: match_id, match_info: null, subtournament_id: props.openModalmatch_info.subtournament_id }, function (response, logout) {
        if (logout === 1) {


        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
          setOpen(false)

        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }
        setloading(false)

      });
    }
  }

  return (

    <>
      <Portal open={openPortalModuleJudges ? true : false}>
        <Segment
          style={{
            left: '40%',
            position: 'fixed',
            top: '10%',
            width: 400,
            zIndex: 2002,
          }}
        >
          <JudgesList addJudges={false} selectionType={1} rootHeight={300} setselectedJudgesList={setselectedJudgesList} />

          <Button
            content='ZAMKNIJ'
            size='mini'
            fluid
            style={{ marginTop: 5 }}
            onClick={() => setopenPortalModuleJudges(null)}
          />
        </Segment>
      </Portal>

      <Modal

        open={open}
        centered={width < 900 ? false : true}

        size='large'


      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='left'>
                  <Icon name='edit' color="red" />

                  <Header.Content>Edycja informacji
                    <Header.Subheader>
                      Pojedynek: {open ? <>{props.openModalmatch_info.row.member1_name} vs {props.openModalmatch_info.row.member2_name}</> : ""}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content style={{ backgroundColor: "#e0e1e2" }} >
          <Modal.Description >
            {open && match_info ?
              <Form size='mini' onSubmit={saveDataMI}>
                {getmatch_info(match_info)}
                {isAS ?
                  <Button size='mini' fluid color='green' loading={loading} disabled={loading} type='submit'>ZAPISZ</Button>
                  : <>
                    <Button size='mini' fluid color='green' disabled={true} >ZAPISZ</Button>
                  </>}
              </Form>
              : ""}
            {isAS ? "" : noSubIsActive()}
            <Message size='mini' info>
              Potrzebujesz dodać inne informacje? Nie ma problemu! Napisz do Nas, informacje dostępne w zakładce <span className='tllink cursorPointer' onClick={() => history.push("/kontakt")} >KONTAKT</span>
            </Message>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {props.openModalmatch_info ? <Button size="tiny" floated='left' onClick={() => restoreStats()} content={t("Reset").toUpperCase()} /> : ""}

          <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />
        </Modal.Actions>
      </Modal>
    </>


  )
}

export default EditLeagueMatchInfo;