import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Message, Table, Button, Segment, Label } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import { bruttoToNetto, changeInitialScale, statusPayments } from '../../../../myfunctions';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import ShowPagination from '../../../../tools/ShowPagination';
import BuyProductCompany from '../../SinglePage/Other/BuyProductCompany';

function MyPayments(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);

  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const { width } = useWindowDimensions();
  const [rowData, setrowData] = useState({ current: [], filter: null, data: [], activePage: 1 });

  const [rowDataPayable, setrowDataPayable] = useState({ current: [], filter: null, data: [], activePage: 1 });

  useEffect(() => {
    changeInitialScale(0.5)
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MojePłatności")
    }
    getUserPayments()
    getUserPayableServices() 
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps



  function getUserPayments() {
    ConnectWS("/generator/getUserPayments", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        let tmparr2 = []
        response.data.forEach((row1) => {
         // if (row1.payment_status === "SUCCESS" || row1.payment_status === "PENDING" || 1==1) {
            tmparr2.push({ ...row1 })
         // }
        })

        setrowData({ current: tmparr2, filter: null, data: tmparr2, activePage: 1 })
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }


  function getUserPayableServices() {
    ConnectWS("/generator/getUserPayableServices", authContext.token, {}, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        let tmparr2 = []
        response.data.forEach((row1) => {
         // if (row1.payment_status === "SUCCESS" || row1.payment_status === "PENDING" || 1==1) {
            tmparr2.push({ ...row1 })
         // }
        })

        setrowDataPayable({ current: tmparr2, filter: null, data: tmparr2, activePage: 1 })
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }

    });
  }


  function getFvData(data) {
    if (data) {
      try {
        if (data.nip) {
          return <div style={{ lineHeight: "12px", fontSize: 12 }}>
            <b>Nazwa firmy</b>: {data.company_name} <br />
            <b>NIP</b>: {data.nip} <br />
            <b>Adres</b>: {data.street} <br />
            <b>Miejscowość</b>: {data.place} <br />
            <b>Poczta</b>: {data.postal_code} {data.postal} <br />

          </div>
        } else {
          return <div style={{ lineHeight: "12px", fontSize: 12 }}>
            <b>Imie</b>: {data.name} <br />
            <b>Nazwisko</b>: {data.surname} <br />
            <b>Adres</b>: {data.street} <br />
            <b>Miejscowość</b>: {data.place} <br />
            <b>Poczta</b>: {data.postal_code} {data.postal} <br />
          </div>
        }

      } catch (e) {
        return ""
      }
    }

  }


  function getFileFv(payment_id,fv_nr){
    const authHeader = "Bearer " + authContext.token

    const options = {
      headers: {
        Authorization: authHeader
      }
    };

    fetch(process.env.REACT_APP_API+"/media/getFV/"+payment_id, options)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);

  
          var a = document.createElement('a');
          a.href = url;
          a.download = fv_nr;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again    
       

      });
  }


  return (
    <>
      <Header as='h5' textAlign='center' block>
        <Icon name='payment' />
        <Header.Content>Moje płatności</Header.Content>
      </Header>
      <Divider />
      {rowDataPayable && rowDataPayable.current.length > 0 ?
        <Segment color='red' style={{backgroundColor:"#f0f0f0",marginBottom:30}}> 
             <Label style={{marginBottom:10}} color='red' ribbon>
          DO ZAPŁATY
        </Label>
           <div style={{ overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
          <Table striped compact celled unstackable size='small' color="grey">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell textAlign='center' >Data</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Usługa</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Opis</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={1} singleLine>Kwota brutto</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={1} singleLine>Kwota netto</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowDataPayable.current.map((line3, i) => <Table.Row>
                <Table.Cell textAlign='center' width={1} singleLine>{line3.order_dt}</Table.Cell>
                <Table.Cell textAlign='center'>{line3.service_name}<br/>
                <b>Nazwa na fakturze:</b> {line3.fv_title}</Table.Cell>
                <Table.Cell textAlign='center' width={3}>{line3.service_description}</Table.Cell>
                <Table.Cell textAlign='center'>{line3.price} PLN</Table.Cell>
                <Table.Cell textAlign='center'>{bruttoToNetto(line3.price)} PLN</Table.Cell>
                <Table.Cell textAlign='center'>
                  {line3.realized_dt ? 
                  <BuyProductCompany payable_service_id={line3.payable_service_id} buyProductType={5} messageProduct={"Subskrypcja"} isToken={true} isDemo={false} issmall={true} btnname={"ZAPŁAĆ"} price={line3.price} />
                  :"Oczekuje na realizacji funkcjonalności"}
                  </Table.Cell>
               
              </Table.Row>)}
            </Table.Body>
          </Table>
        </div>
        </Segment>
        :
        ""
      }

     
        <>  <div style={{ overflowX: "auto", width: width < 700 ? width - 105 : "100%" }}>
          <Table striped compact celled unstackable size='small' color="grey">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell textAlign='center' >Data</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Usługa</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={1} singleLine>Kwota brutto</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Dane do faktury</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Faktura</Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowData && rowData.current.length > 0 ? rowData.current.map((line3, i) => <Table.Row>
                <Table.Cell textAlign='center' width={1} singleLine>{line3.create_ts}</Table.Cell>
                <Table.Cell textAlign='center'>{line3.fv_title}</Table.Cell>
                <Table.Cell textAlign='center'>{statusPayments(line3.payment_status, true)}</Table.Cell>
                <Table.Cell textAlign='center'>{line3.price} PLN</Table.Cell>
                <Table.Cell>{getFvData(line3.fv_data)}</Table.Cell>
                <Table.Cell textAlign='center'>
                  {line3.fv_nr && line3.fv_url ? <Button title={line3.fv_nr} onClick={()=>getFileFv(line3.payment_id,line3.fv_nr)} size='mini' icon labelPosition='left'>
                    <Icon name='file alternate' />
                    FAKTURA
                  </Button> : "OCZEKUJE NA WYGENEROWANIE"}
                </Table.Cell>
              </Table.Row>) : <Table.Row > <Table.Cell colSpan={6} textAlign='center'><Message>Lista jest pusta</Message> </Table.Cell></Table.Row>}
            </Table.Body>
          </Table>
        </div>
          <ShowPagination myTab={rowData} setMyTab={setrowData} rowCurrent={10} />
        </>
       
      
    </>
  )
}

export default MyPayments;