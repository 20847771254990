import React, { useState, useEffect, useContext } from 'react';
import { Button,  Icon, Message, Table } from 'semantic-ui-react';

import AuthContext from '../../context/authContext';
import { ConnectWS } from '../../ConnectWS';
import { useToasts } from 'react-toast-notifications';
import { t } from 'i18next';



function EditLeagueGlobalStatsOther(props) {

    const authContext = useContext(AuthContext);

    const [globalStats, setglobalStats] = useState(null)
    const [loading, setloading] = useState(false)

    const [jsonOpt, setjsonOpt] = useState([{ // eslint-disable-line no-unused-vars
        "icon": "star",
        "name": "mvp",
        "sort": 1,
        "type": "inputboolicon",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "in_match",
        "sort": 2,
        "type": "inputbool",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    },  {
        "icon": "point",
        "name": "matche_cards",
        "sort": 3,
        "type": "boolimagecard",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    },{
        "icon": "point",
        "name": "count_assist",
        "sort": 4,
        "type": "inputnumber",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    },  {
        "icon": "point",
        "name": "technical_foul",
        "sort": 5,
        "type": "inputnumber",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_scored_own_goals",
        "sort": 6,
        "type": "inputnumber",
        "hide_viewers": true,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }, {
        "icon": "point",
        "name": "points_scored_goals",
        "sort": 16,
        "type": "inputnumber",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    },{
        "icon": "point",
        "name": "points_scored",
        "sort": 17,
        "type": "inputnumber",
        "hide_viewers": false,
        "team1_id": null,
        "team2_id": null,
        "team1_members": null,
        "team2_members": null
    }])

    const { addToast } = useToasts();

    useEffect(() => {
       
        if (props && props.openModalmatch_details_ex && props.openModalmatch_details_ex.row && props.openModalmatch_details_ex.row.match_details_ex && props.openModalmatch_details_ex.row.match_details_ex.version) {
            setglobalStats(props.openModalmatch_details_ex.row.match_details_ex)
        }

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [props])// eslint-disable-line react-hooks/exhaustive-deps

    function closeModal() {
        props.setopenModalmatch_details_ex({ open: false, row: null, onlyView: 1, st: null })
        props.setOpen(false)
    }


    function checkISOn(row) {
        try {
            if (globalStats.version && globalStats.members[row] && globalStats.members[row].name === row) {
                return <Icon name="check" color='green' className='cursorPointer' onClick={()=>removeStats(row)} />
            } else {
                return <Icon name="x" color='grey' />
            }
        } catch (e) {
            console.log(e)
            return <Icon name="x" />
        }

    }
    function checkOnlyViewAdmin(row) {
        try {
            if (globalStats.version && globalStats.members[row] && globalStats.members[row].hide_viewers) {
                return <Icon name="check" color='green' className='cursorPointer' onClick={()=>onChangeView(row,false)} />
            } else {
                return <Icon name="x" color='grey' className='cursorPointer' onClick={()=>onChangeView(row,true)}/>
            }
        } catch (e) {
            console.log(e)
            return <Icon name="x" />
        }

    }

    function onChangeView(row,value){
        try {
            let tmp = JSON.parse(JSON.stringify(globalStats))
            tmp.members[row].hide_viewers=value
            setglobalStats(tmp)
        } catch (e) {
            console.log(e)
            
        }
    }

    function removeStats(row){
        try {
            let tmp = JSON.parse(JSON.stringify(globalStats))
            delete  tmp.members[row];
            setglobalStats(tmp)
        } catch (e) {
            console.log(e)
            
        }
    }

    function checkIsOff(row){
        try {
           if(globalStats.version && globalStats.members[row.name]){
            return false
           }
           return true
        } catch (e) {
            console.log(e)
            return false
            
        }
    }

    function addRow(row,i){
        try {
            
            let tmp = JSON.parse(JSON.stringify(globalStats))
            tmp.members[row.name] = row
            setglobalStats(tmp)
        } catch (e) {
            console.log(e)
           
            
        }
    }
    


    function saveDb() {

        
        setloading(true)
        ConnectWS("/generator/setSubtournamentMatchJsonTemplate", authContext.token, { subtournament_id: props.openModalmatch_details_ex.subtournament_id, match_info: props.openModalmatch_details_ex.row.match_info, match_details_ex: globalStats }, function (response, logout) {
            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                closeModal()
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloading(false)

        });
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Message info>W tym oknie możesz zmienić globalnie zbierane statystyki uczestników dla tej ligi</Message>

            <Table size='small' celled striped compact unstackable >
                <Table.Header>
                    <Table.Row>
                      
                        <Table.HeaderCell width={1} textAlign='center'>STATYSTYKI</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign='center'>WIDOCZNE TYLKO DLA ADMINA</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign='center'>WŁĄCZONE</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {globalStats  ?
                        Object.keys(globalStats.members).map((row, i) => <Table.Row key={i}>
                            
                            <Table.Cell textAlign='center'>
                                {t("stats." + globalStats.members[row].name)}

                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                                {checkOnlyViewAdmin(row)}
                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                                {checkISOn(row, i)}
                            </Table.Cell>
                        </Table.Row>) : ""}
                        {jsonOpt  ?
                       jsonOpt.map((row, i) => checkIsOff(row) ? <Table.Row key={i}>
                            
                        
                            <Table.Cell textAlign='center'>
                                {t("stats." + row.name)}

                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center'></Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                               <Icon name="add" color='orange' title="dodaj" className='cursorPointer' onClick={()=>addRow(row,i)}/> 
                            </Table.Cell>
                        </Table.Row> : "") : ""}
                </Table.Body>
            </Table>


            

            <Button loading={loading} disabled={loading} color='green' fluid onClick={() => saveDb()}>ZAPISZ KONFIGURACJĘ DLA LIGI</Button>


        </div>
    )
}

export default EditLeagueGlobalStatsOther;