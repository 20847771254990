import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button,  Grid, Header, Icon, Image, Input, Modal, Table } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/logotgver1.svg';

import "cropperjs/dist/cropper.css";
import { ConnectWS, ConnectWSSendData } from '../ConnectWS';

import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import { getInfoNoSubMedia, getTabByFiltr, loadingShow, normalizeStringURLIMG } from '../myfunctions';

import { confirmAlert } from 'react-confirm-alert';
import { FileUpload } from 'primereact/fileupload';
import { locale, addLocale } from 'primereact/api';
import ShowPagination from './ShowPagination';


function AddMediaUserFile({ fileMediaSelect, setfileMediaSelect, disabledButton }) {
    locale('pl');
    addLocale('pl', {
        choose: "Wybierz",
        upload: "Wgraj",
        cancel: "Anuluj",
        pending: "Oczekuje na wgranie",
        completed: "Wysyłany"
    });
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false)
    const authContext = useContext(AuthContext);
    const [loading, setloading] = useState(false);

    const [addFileTG, setaddFileTG] = useState(true);
    const [arrMediaData, setarrMediaData] = useState({ current: [], filter: null, data: [], activePage: 1 });

  

    useEffect(() => {
        if(open){
            setaddFileTG(true)
        }
      
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [open])// eslint-disable-line react-hooks/exhaustive-deps


    function clickOpenModalF(value) {
        if (value) {
            // modal jest otwarty
            getUserFiles()
        } else {

        }
        setOpen(value)
    }

    function getUserFiles() {
        setloading(true)
        ConnectWS("/media/getUserMedia", authContext.token, { file_type: "DOC" }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

            } else if (response && response.status && Number(response.status) === 200) {
              
                if (response.data && response.data.length > 0) {
                    setarrMediaData({ current: response.data.sort((a, b) => new Date(b.create_ts) - new Date(a.create_ts)), filter: null, data: response.data.sort((a, b) => new Date(b.create_ts) - new Date(a.create_ts)), activePage: 1 })

                } else {
                    setarrMediaData({ current: [], filter: null, data: [], activePage: 1 })
                }

            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }

            }

            setloading(false)


        });

    }



    function chooseFileUrl(url) {
        setfileMediaSelect(url)
        clickOpenModalF(false)
    }


    function removeFile(file_id) {

        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować plik?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeFileAcc(file_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });
        function removeFileAcc(file_id) {
            ConnectWS("/media/removeFileFromUserMedia", authContext.token, { file_id: file_id, file_type: "DOC" }, function (response, logout) {

                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    getUserFiles()


                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }

                }

                setloading(false)


            });
        }
    }
    const ref = useRef(null)
    const customBase64Uploader = async (event) => {

        if (event.files.length > 0) {
            let arrFileOK = []
            let arrFileErr = []
            let currCF = 1
            event.files.forEach((row) => {
                callbackPhoto(row, currCF, event.files.length, arrFileOK, arrFileErr)
                currCF++
            })
        }

        function callbackPhoto(nfile, currCF, count, arrFileOK, arrFileErr) {



            var formData = new FormData();
            formData.append('fileData', nfile, normalizeStringURLIMG(nfile.name));
            formData.append('file_type', "DOC");

            ConnectWSSendData("/media/addFileToUserMedia", authContext.token, formData, function (response, logout) {
                if (logout === 1) {
                    arrFileErr.push(nfile)
                } else if (response && response.status && Number(response.status) === 200) {
                    arrFileOK.push(nfile)
                    if(response.data.file_url){
                        addToast(response.data.message, { appearance: 'success' });
                        chooseFileUrl(process.env.REACT_APP_URL_MEDIA +response.data.file_url)
                    }
                 
                } else {
                    arrFileErr.push(nfile)
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }

                }
                try {
                    
                } catch (e) {
                    console.log(e)
                }

            });

        }

    }

    return (
        <>
            <Modal
                onClose={() => ""}
                onOpen={() => clickOpenModalF(true)}
                open={open}
                size='large'
            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='file' color="red" />
                                    <Header.Content>Multimedia</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image floated='right' size='big' alt="logo" centered src={LogoTeamLigaGenerator} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }} >

                    <Modal.Description >

                        {loading ? loadingShow() : addFileTG ? <>
                            <div className="card">


                                <FileUpload accept="application/pdf,image/*"  ref={ref} invalidFileSizeMessageDetail="Maksymalny rozmiar pliku to 1MB"  invalidFileSizeMessageSummary="Bład! za duży plik."  maxFileSize={1000000} customUpload uploadHandler={customBase64Uploader} emptyTemplate={<p className="m-0">Drag and drop.</p>} />
                            </div>
                        </> : <>
                        <Input size='mini' style={{ marginBottom: 10 }} fluid icon='search' onChange={(e, d) => getTabByFiltr(d.value, arrMediaData, setarrMediaData, 10)} placeholder={"Szukaj..."} />

                            {arrMediaData.current ?
                                <Table singleLine size='small' celled striped compact unstackable >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center'>NAZWA</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center'>DATA DODANIA</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {arrMediaData.current && arrMediaData.current.length>0 ? arrMediaData.current.map((row, i) =>

                                            <Table.Row>
                                                <Table.Cell width={1} textAlign='center'>
                                                    <Button size='mini' onClick={()=>chooseFileUrl(process.env.REACT_APP_URL_MEDIA +row.file_url)}>WYBIERAM</Button>
                                                </Table.Cell>
                                                <Table.Cell width={1} textAlign='center'>
                                                <Icon name="file"/> <a className='tllink' target='_blank' rel="noreferrer" href={process.env.REACT_APP_URL_MEDIA +row.file_url}>
                                               {row.file_name}
                                                </a>
                                                </Table.Cell>
                                                <Table.Cell width={1} textAlign='center'>
                                                    {row.create_ts}
                                                </Table.Cell>
                                                <Table.Cell width={1} textAlign='center'>
                                                <Icon className='cursorPointer' size='large' onClick={() => removeFile(row.file_id)}
                                                 name="trash"  title="Skasuj plik" />    
                                                </Table.Cell>
                                            </Table.Row>
                                        ) :   <Table.Row>
                                        <Table.Cell colSpan='5' textAlign='center'>
                                            BRAK PLIKÓW
                                        </Table.Cell>
                                        </Table.Row>}
                                    </Table.Body>
                                </Table>

                                : ""}
                                  <ShowPagination darkOn={false} darkForce={false} myTab={arrMediaData} setMyTab={setarrMediaData} rowCurrent={10} />
                           
                        </>}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {addFileTG ?
                        <Button floated='left' color='blue' onClick={() => {
                            setaddFileTG(false)
                            getUserFiles()
                        }}>PRZEJDŹ DO LISTY PLIKÓW</Button>
                        :
                        <Button floated='left' color='blue' onClick={() => setaddFileTG(true)}>DODAJ PLIK</Button>
                    }
                    <Button color='black' onClick={() => {
                        clickOpenModalF(false)
                    }}>
                        ZAMKNIJ
                    </Button>

                </Modal.Actions>
            </Modal> 
            {open ? "" : disabledButton ? <><Button onClick={() => authContext.changeModalInfo({ nameModal: "globalViewValue", name: "Informacja", value: getInfoNoSubMedia() })} type='button' size='mini' color='grey'>WYBIERZ PLIK</Button><br/></> :<><Button type='button' size='mini' disabled={disabledButton} onClick={() => clickOpenModalF(true)} color='grey'>{disabledButton ? "WYBIERZ PLIK ZABLOKOWANE BRAK AKTYWNEJ SUBSKRYPCJI" : "WYBIERZ PLIK"}</Button><br/></>}


        </>
    )
}

export default AddMediaUserFile;