import React, { useState, useEffect } from 'react';
import { Icon, Header,  Button,  Grid, Modal, Image, HeaderSubheader, FormRadio, Card } from 'semantic-ui-react'
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import pucharpatg from '../../../../assets/pucharpatg.png';
import pucharpatg1 from '../../../../assets/pucharpatg1.png';
import SupportPage from '../../../../tools/SupportPage/SupportPage';
import CallBackTG from '../../../../tools/CallBackTG';
import {  packageColor } from '../../../../myfunctions';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';


function PackagesAssist({ openModalDataPA, setopenModalDataPA }) {

  const [question, setquestion] = useState({});
const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const [showPackageData, setshowPackageData] = useState(null) //0 brazowy // 1 - srebrny // 2 złoty // 3 Diam lite // 4 Diamentowy // 5 Diamentowy Plus 


  useEffect(() => {
    restoreQ()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [openModalDataPA])// eslint-disable-line react-hooks/exhaustive-deps

  function restoreQ() {
    setshowPackageData(null)
    setquestion({ q1: null, q2: null, q3: null, q6: null   })
  }

  function returnQ1() {
    return <div>
      <Header as='h3' style={{ fontStyle: "italic" }}>
        Jaki rodzaj rozgrywek organizujesz ?
        <HeaderSubheader>

        </HeaderSubheader>
      </Header>
      <FormRadio
        style={{ marginTop: 10 }}
        label='Jednorazowe lub bardzo sporadyczne'
        value={1}
        checked={question.q1 === 1}
        onChange={() => analysisQuestion("q1", 1)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Tylko zajęcia (np. treningi)'
        value={2}
        checked={question.q1 === 2}
        onChange={() => setshowPackageData(3)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Ligowe lub cykliczne'
        value={3}
        checked={question.q1 === 3}
        onChange={() => analysisQuestion("q1", 3)}
      />
    </div>
  }

  function returnQ2() {
    return <div>
      <Header as='h3' style={{ fontStyle: "italic" }}>

        Ile osób będzie uczestniczyło w rozgrywkach?
        <HeaderSubheader>

        </HeaderSubheader>
      </Header>
      <FormRadio
        style={{ marginTop: 10 }}
        label='Mniej niż 9 w maksymalnie 2 kategoriach'
        onChange={() => setshowPackageData(0)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Mniej niż 40'
        onChange={() => setshowPackageData(1)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='40 lub więcej'
        onChange={() => setshowPackageData(2)}
      />
    </div>
  }
  function returnQ3() {
    return <div>
      <Header as='h3' style={{ fontStyle: "italic" }}>

        Czy jesteś zainteresowany dodatkowym modułem do organizacji treningów lub jakiś innych eventów?
        <HeaderSubheader>

        </HeaderSubheader>
      </Header>
      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak'
        onChange={() => setshowPackageData(5)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Nie'
        onChange={() => analysisQuestion("q6", 1)}
      />

    </div>
  }

  function returnQ6() {
    return <div>
      <Header as='h3' style={{ fontStyle: "italic" }}>

       Czy jesteś zainteresowany dedykowanym panelem do wprowadzania wyników przez uczestników ligi?
        <HeaderSubheader>

        </HeaderSubheader>
      </Header>
      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak'
        onChange={() => setshowPackageData(5)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Nie'
        onChange={() => analysisQuestion("q2", 1)}
      />

    </div>
  }


  function returnQ4() {
    return <div>
      <Header as='h4'  style={{ fontStyle: "italic" }}>
        <ol>
          <li>Czy chciałbyś posiadać dedykowana witrynę klubu/organizacji/ligi?</li>
          <li>Lub mieć możliwość osadzania komponentów na własnej stronie internetowej?</li>
          <li>Lub utworzysz ligę</li>
          <li>Lub będą to turnieje połączone w cykl?</li>

        </ol>
      </Header>


      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak - Utworzę ligę dla drużyn'
        onChange={() => analysisQuestion("q3", 1)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak - Utworzę ligę dla osób więcej niż 2 kategorie'
        onChange={() => setshowPackageData(4)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak - Utworzę ligę dla osób max 2 kategorie'
        onChange={() => setshowPackageData(3)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak - Będą to turnieje cykliczne'
        onChange={() => setshowPackageData(4)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Nie'
        onChange={() => setshowPackageData(3)}
      />

    </div>
  }

  function returnQ5() {
    return <div>
      <Header as='h4'  style={{ fontStyle: "italic" }}>
        <ol>
          <li>Czy chciałbyś do każdego meczu ligowego móc dodać zdjęcia (album google) lub załączyć  protokół w formie plikowej?</li>
          <li>Lub chciałbyś podpiąć własną domenę?</li>
        </ol>
      </Header>


      <FormRadio
        style={{ marginTop: 10 }}
        label='Tak'
        onChange={() => setshowPackageData(5)}
      />
      <FormRadio
        style={{ marginTop: 10 }}
        label='Nie'
        onChange={() => setshowPackageData(4)}
      />

    </div>

  }

  function analysisQuestion(oj, value) {
    try {
      let tmp = { ...question }
      tmp[oj] = value
      console.log(tmp)
      setquestion(tmp)
    } catch (e) {
      console.log(e)
    }
  }


  function showBodyData(dshowPackageData) {
    //0 brazowy // 1 - srebrny // 2 złoty // 3 Diam lite // 4 Diamentowy // 5 Diamentowy Plus 
    if (dshowPackageData === 0) {
      return <Card.Group style={{ marginBottom: 10 }} centered>
      <Card style={{ textAlign: "center" }} className={"cardpaybrown"}>
        <Card.Content style={{ lineHeight: "14px" }}>
          <Card.Header style={{ lineHeight: "10px" }}>{packageColor(1)} PAKIET BRĄZOWY</Card.Header>
          <span style={{ fontSize: 13 }}>DARMOWY</span>
        </Card.Content>
      </Card>
    </Card.Group>
    } else if (dshowPackageData === 1) {
      return <Card.Group style={{ marginBottom: 10 }} centered>
      <Card style={{ textAlign: "center" }} className={"cardpaysilver"}>
        <Card.Content style={{ lineHeight: "14px" }}>
          <Card.Header style={{ lineHeight: "10px" }}>{packageColor(2)} PAKIET SREBRNY</Card.Header>
          <span style={{ fontSize: 13 }}>1 TOKEN</span>
        </Card.Content>
      </Card>
    </Card.Group>
    } else if (dshowPackageData === 2) {
      return <Card.Group style={{ marginBottom: 10 }} centered>
      <Card style={{ textAlign: "center" }} className={"cardpayw"}>
        <Card.Content style={{ lineHeight: "14px" }}>
          <Card.Header style={{ lineHeight: "10px" }}>{packageColor(3)} PAKIET ZŁOTY</Card.Header>
          <span style={{ fontSize: 13 }}>2 TOKENY</span>
        </Card.Content>
      </Card>
    </Card.Group>
    } else if (dshowPackageData === 3) {
      return <Card.Group style={{ marginBottom: 10 }} centered>
      <Card style={{ textAlign: "center" }} className={"cardpayd cardpayw"}>
        <Card.Content style={{ lineHeight: "14px" }}>
          <Card.Header style={{ lineHeight: "10px", marginBottom: 20 }}>{packageColor(-1)} PAKIET DIAMENTOWY LITE</Card.Header>
          <span style={{ fontSize: 13 }}>SUBSKRYPCJA MIESIĘCZNA <br /> nie odnawia się automatycznie</span>
        </Card.Content>
      </Card>
    </Card.Group>
    } else if (dshowPackageData === 4) {
      return <Card.Group style={{ marginBottom: 10 }} centered>
      <Card style={{ textAlign: "center" }} className={"cardpayd cardpayw"}>
        <Card.Content style={{ lineHeight: "14px" }}>
          <Card.Header style={{ lineHeight: "10px", marginBottom: 20 }}>{packageColor(4)} PAKIET DIAMENTOWY</Card.Header>
          <span style={{ fontSize: 13 }}>SUBSKRYPCJA MIESIĘCZNA <br /> nie odnawia się automatycznie</span>
        </Card.Content>
      </Card>
    </Card.Group>
    } else if (dshowPackageData === 5) {
      return <Card.Group style={{ marginBottom: 10 }} centered>
        <Card style={{ textAlign: "center" }} className={"cardpayd cardpayw"}>
          <Card.Content style={{ lineHeight: "14px" }}>
            <Card.Header style={{ lineHeight: "10px", marginBottom: 20 }}>{packageColor(5)} PAKIET DIAMENTOWY PLUS</Card.Header>
            <span style={{ fontSize: 13 }}>SUBSKRYPCJA MIESIĘCZNA <br /> nie odnawia się automatycznie</span>
          </Card.Content>
        </Card>
      </Card.Group>
    }
    return <>Ups</>
  }

  return (
    <Modal
      open={openModalDataPA ? true : false}
      size={'large'}
      centered={width < 900 ? false : true}

    >
      <Modal.Header>
        <Grid >
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as='h4' textAlign='center'>
                <Icon name='user' />
                <Header.Content>AsystentTG</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" >
              <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Header>
      <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
        <Modal.Description >
          {showPackageData === null ? <Grid stackable>
            <Grid.Row >
         
              <Grid.Column width={10} style={{paddingLeft:50}}>
                <div style={{ textAlign: "left", marginTop: 30, marginBottom: 30 }}>
                  {question.q1 === null ? returnQ1() : ""}
                  {question.q1 === 1 && question.q2 === null ? returnQ2() : ""}
                  {question.q1 === 3 && question.q2 === null && question.q6 === null ? returnQ3() : ""}
                  {question.q2 === 1 && question.q3 === null ? returnQ4() : ""}
                  {question.q6 === 1 && question.q2 === null  ? returnQ6() : ""}
                  {question.q3 === 1 ? returnQ5() : ""}
                  

                </div>
              </Grid.Column>
              <Grid.Column width={6} verticalAlign='middle'>
                <Image
                  alt="puchartg"
                  style={{ width: 160, margin: "0 auto" }}
                  src={pucharpatg1}

                />
              </Grid.Column>
            </Grid.Row>
          </Grid> : <><Grid stackable>
            <Grid.Row >
              <Grid.Column width={10}>
                <div style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
                  {showBodyData(showPackageData)}
                  <div style={{ marginTop: 10 }}>
                    W razie dalszych wątpliwości możesz dodać nowe zapytanie, lub zamówić rozmowę z Naszym specjalistą.

                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={6} verticalAlign='middle'>
                <Image
                  alt="puchartg"
                  style={{ width: 160, margin: "0 auto" }}
                  src={pucharpatg}

                />

              </Grid.Column>
            </Grid.Row>
          </Grid>
            <CallBackTG />
            <SupportPage offInfo={true} />
          </>}

        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => restoreQ()}>
          ROZPOCZNIJ OD NOWA
        </Button>
        <Button color='black' onClick={() => setopenModalDataPA(null)}>
          ZAMKNIJ
        </Button>

      </Modal.Actions>
    </Modal>
  )
}

export default PackagesAssist;